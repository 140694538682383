.snackbar__snackbar___2yObH {
  background-color: #3b3b3b;
  padding: 24px 48px;
  width: 100%;
  max-width: 568px;
  left: 0;
  right: 0;
  border-radius: 112px;
  z-index: 2000;
}

.snackbar__active___183ye {
  -webkit-transform: translateY(-100%) !important;
          transform: translateY(-100%) !important;
}

.snackbar__button___Do5gk {
  margin: 0;
  padding: 0;
  color: #00A4B7 !important;
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.snackbar__button___Do5gk:hover {
  background-color: #3b3b3b !important;
}

/* Overriding the react-toolbox style on buttons */
.snackbar__button___Do5gk > span:not([data-react-toolbox='tooltip']) {
  line-height: 1.1em;
  display: inherit;
}

.snackbar__label___2F1LF {
  color: #FFFFFF;
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.snackbar__action___ggHwO .snackbar__label___2F1LF {
  margin-right: 24px;
}

.snackbar__iconWrapper___3_L7Y {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.snackbar__icon___VVLf7 {
  fill: white;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1rem;
}

@media screen and (max-width: 568px) {
  .snackbar__snackbar___2yObH {
    border-radius: 0;
  }
}
