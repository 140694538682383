.option-boxes__assessmentOptions___3OADx {
  border: 1px solid #DDDDDD;
  border-radius: 4px;
}

.option-boxes__assessmentOption___2EjoD {
  padding: 1rem;
}

.option-boxes__assessmentOption___2EjoD:not(:first-child) {
  border-top: 1px solid #DDDDDD;
}

.option-boxes__assessmentOptionHeader___3wc3B {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.375rem;
}

.option-boxes__assessmentOptionBody___14U8T {
  font-size: 0.75rem;
  margin-bottom: 1rem;
}
