.image-atom__longDescription___hoi2U {
}

.image-atom__atomImage___QK0hc {
  margin: 0 auto;
  text-align: center;
}

.image-atom__atomImage___QK0hc img {
  max-width: 100%;
}

.image-atom__caption___3Q1P5 {
  padding: 10px;
  font-style: italic;
  font-size: 14px;
}

.image-atom__error___2VBD3 {
  color: #C84E50;
}

/* use the standard link color for contrast and clarity  */
.image-atom__link___1MFme {
  color: #0000EE;
}

.image-atom__link___1MFme:hover {
  cursor: pointer;
}

.image-atom__spinner___30Lp- {
  padding-top: 1rem;
}

.image-atom__hidden___1USp7 {
  display: none;
}
