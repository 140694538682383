.external-button__launcherContainer___3ADzC {
  text-align: center;
}

.external-button__icons___3Mwe5 {
  width: 1rem;
  height: 1rem;
  display: block;
}

.external-button__previewBtn___2DjVT {
  font-size: 12px;
  font-weight: 600;
  padding: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.external-button__prominent___1bUVL {
  padding: 40px 20px;
  font-size: 16px;
}

.external-button__newTabIcons___2cGzM {
  width: 2rem;
  height: 2rem;
}
