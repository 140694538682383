.feedback-emoji__emoji___2LSF0 {
  background-size: cover;
  background-image: url(/jsx/img/feedback-emoji-sprite.442bdce561a47ccd56e3.png);
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
  display: inline-block;
}

.feedback-emoji__emoji___2LSF0.feedback-emoji__not_right___1JC0L {
  background-position: 0 0;
}

.feedback-emoji__emoji___2LSF0.feedback-emoji__looks_broken___30tfW {
  background-position: -2.5rem 0;
}

.feedback-emoji__emoji___2LSF0.feedback-emoji__cannot_answer___24J5I {
  background-position: -5rem 0;
}

.feedback-emoji__emoji___2LSF0.feedback-emoji__have_idea___1xNML {
  background-position: -7.5rem 0;
}
