.legal-stuff__rememberMeContainer___3wLQP {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: left;
  -webkit-justify-content: left;
      -ms-flex-pack: left;
          justify-content: left;
  width: 100%;
}

.legal-stuff__bottomMatter___2q-IU {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  line-height: 20px;
}

.legal-stuff__bottomMatterLink___2yE8r {
  color: #FFFFFF;
  font-size: 12px;
  margin: 0 8px;
  text-decoration: none;
  font-weight: 600;
}

.legal-stuff__bottomMatterLink___2yE8r::after,
.legal-stuff__bottomMatterLink___2yE8r:active::after {
  background: #FFFFFF;
}

.legal-stuff__copyright___3VeFc {
  color: #FFFFFF;
  font-size: 12px;
  margin-top: 24px;
  text-align: center;
  white-space: normal;
}

@media screen and (min-width: 480px) {
  .legal-stuff__copyright___3VeFc {
    white-space: nowrap;
  }
}
