.hero-title-nav__backIconLink___32pQC {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0;
  border-radius: 50%;
  padding: 0.5rem;
  position: relative;
  top: 0.125rem;
  right: 1rem;
}

.hero-title-nav__backIconLink___32pQC .hero-title-nav__backIcon___2G0Fr {
  width: 1.25rem;
  height: 1.25rem;
}

.hero-title-nav__containerLoading___17oHi {
  width: 100%;
}

.hero-title-nav__heading___CRZIS {
  font-size: 1.125rem;
  font-weight: bold;
}

.hero-title-nav__headingEllipsis___2oxzv {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .hero-title-nav__heading___CRZIS {
    font-size: 2.125rem;
    font-weight: 400;
    margin: 0.5rem 0;
  }
}
