.copy-text-input__copyTextInput___3xBIh {
  border-bottom: 1px solid #727272;
}
.copy-text-input__input___uhZ4D {
  font: inherit;
  background: transparent;
  border: 0;
  line-height: 2.25rem;
  margin-left: 1rem;
  width: 100%;
}
.copy-text-input__input___uhZ4D:focus{
  outline-color: #064560;
}

.copy-text-input__noMargin___MunWo {
  margin-left: 0;
}

.copy-text-input__copyLink___1IZiB {
  background: transparent;
  padding: 0;
  min-width: 8.5rem;
}
