.table-of-contents__heading___21Dye {
  cursor: pointer;
}

.table-of-contents__pointerCursor___1mArQ {
  cursor: pointer;
}

.table-of-contents__checkbox___RtFfv {
  padding: 0 !important;
  margin: 0 1rem 0 0 !important;
}

.table-of-contents__checkbox___RtFfv > div {
  margin: 0;
  vertical-align: bottom;
}

.table-of-contents__cardContainer___neDQG {
  border-radius: 0.25rem;
}

.table-of-contents__loDescription___1wAcW,
.table-of-contents__loConceptDescription___1p1j- {
  width: 100%;
}

.table-of-contents__rightArrow____xS5X {
  fill: transparent;
}

.table-of-contents__showRightArrow___2vKDd:hover .table-of-contents__rightArrow____xS5X {
  fill: #484848;
}

.table-of-contents__downArrow___eXBO6 {
  fill: transparent;
}

.table-of-contents__showDownArrow___2RcBs:hover .table-of-contents__downArrow___eXBO6 {
  fill: #484848;
}

.table-of-contents__upArrow___11ffo {
  fill: transparent;
}

.table-of-contents__showUpArrow___3Lx5W:hover .table-of-contents__upArrow___11ffo {
  fill: #484848;
}

.table-of-contents__rightArrowAlwaysInvisible___1f8tG {
  fill: transparent !important;
}

.table-of-contents__selectedChapter___3IUci {
  border-color: #008392;
}

.table-of-contents__disabled___yh6R_ {
  color: #ABABAB !important;
  cursor: default;
}

.table-of-contents__errorIcon___1EwrV {
  fill: #C84E50 !important;
}

.table-of-contents__loRow___10C1c:hover {
  background: #F8F8F8;
}

.table-of-contents__titleDisabledNotice___3QD9r {
  color: #F28411;
}

.table-of-contents__warningTooltip___2W9DX {
  z-index: 3000 !important;
}

.table-of-contents__subLosList___19kMr {
  padding: 0.0rem;
  margin: 0.0rem 0.3125rem 0.3125rem;
}

.table-of-contents__subLo___2xUq1 {
  list-style-type: none;
}
