.spinner-text__dummyContent___cbCwy {
  height: 20px;
  width: 183px;
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eee), color-stop(18%, #ddd), color-stop(33%, #eee));
  background: -webkit-linear-gradient(left, #eee 8%, #ddd 18%, #eee 33%);
  background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
  background-size: 800px 104px;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: spinner-text__placeHolderShimmer___1DtOE;
          animation-name: spinner-text__placeHolderShimmer___1DtOE;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  border-radius: 2px;
}

.spinner-text__header___PFj46 {
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 32px;
  font-weight: 600;
  padding-bottom: 16px;
  font-size: 24px;
}

.spinner-text__dummyContent1___SVX6M {
  width: 350px;
}

.spinner-text__dummyContent2___34zuv {
  width: 215px;
}

.spinner-text__dummyContent3___13w83 {
  width: 150px;
}

.spinner-text__dummyContent4___2aJj_ {
  width: 300px;
}

.spinner-text__dummyListItem___3vr3g:hover {
  background: inherit;
}

@-webkit-keyframes spinner-text__placeHolderShimmer___1DtOE {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

@keyframes spinner-text__placeHolderShimmer___1DtOE {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}
