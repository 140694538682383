.async-selector__asyncSelectorNote___1neXN {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #727272;
}

.async-selector__asyncSelectorError___v2SMz {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #C84E50;
}
