.atom-wrapper-overlay__container___39bK_ {
  display: grid;
}

.atom-wrapper-overlay__stacked___305yC {
  grid-row: 1;
  grid-column: 1;
}

.atom-wrapper-overlay__hidden___2pElZ {
  visibility: hidden;
}
