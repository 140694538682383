.lms-resync-warning__container___3dUQf {
  background: #FFF9F2;
  border: 1px solid #F28411;
  border-radius: 0.25rem;
}

.lms-resync-warning__warningIcon___3xYmM {
  fill: #F28411;
  margin-right: 0.5rem;
}

.lms-resync-warning__text___3xU9c {
  color: #C15100;
}

.lms-resync-warning__button___3e0R0 {
  color: #C15100;
  border-color: #F28411;
  margin-left: 1rem;
}

.lms-resync-warning__button___3e0R0:hover {
  border-color: #C15100;
}

.lms-resync-warning__copyIcon___dMccu {
  fill: #F28411;
}
