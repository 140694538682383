.toggle-button-group__togButton___1iJ64 {
  border: 0.0625rem solid;
  border-color: #CCCCCC;
}

.toggle-button-group__togButton___1iJ64 input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

.toggle-button-group__togButton___1iJ64 input[type='radio']:checked + label {
  color: white;
  background-color: #008392;
}

.toggle-button-group__togButton___1iJ64 input[type='radio']:focus + label {
  outline: 0.3125rem auto #064560;
  outline-offset: 0.156rem;
}

.toggle-button-group__label___Scsie {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: #727272;
  padding: 0 1.25rem;
  cursor: pointer;
}

.toggle-button-group__togButton___1iJ64:not(:first-child):not(:last-child) {
  border-left-style: none;
}

.toggle-button-group__togButton___1iJ64:first-of-type {
  border-radius: 0.25rem 0rem 0rem 0.25rem;
}

.toggle-button-group__togButton___1iJ64:last-child {
  border-radius: 0rem 0.25rem 0.25rem 0rem;
  border-left-style: none;
}

.toggle-button-group__icon___1Zf6h {
  vertical-align: sub;
  margin-right: 0.5rem;
}

.toggle-button-group__togButton___1iJ64 input[type='radio']:checked + label .toggle-button-group__icon___1Zf6h {
  fill: white;
}
