.modal__dialogFullscreen___2-lCt {
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  border-radius: 0;
  overflow: auto;
  -webkit-align-self: start;
      -ms-flex-item-align: start;
          align-self: start;
}

.modal__dialogNonFullscreen___1rlAk {
  max-width: 628px;
}

.modal__stickyPadding___1bb3Z {
  height: -webkit-calc(100vh - 4.25rem);
  height: calc(100vh - 4.25rem);
  max-height: -webkit-calc(100vh - 4.25rem);
  max-height: calc(100vh - 4.25rem);
}

.modal__actionsContainer___ICCgk {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.modal__leftActionContainer___JLAsV {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.modal__rightActionContainer___1U3Fb {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: right;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin: -0.5rem -0.5rem 0 -0.5rem; /** this extends a little outside the dialog body **/
}

.modal__dialogBodyFullscreen___13NtA {
  padding: 1rem;
}

.modal__dialogBodyNonFullscreen___lrUe4 {
  padding: 1.5rem;
}

.modal__noPad___1DYyH {
  padding: 0;
}

.modal__hideModalButton___2D7vX {
  top: 8px;
  right: 8px;
  bottom: 8px;
  position: relative;
}

.modal__wrapper___3yuvG {
  z-index: 2000;
}
