div .lrn_widget.lrn_formulaV2 .lrn_response_input_wrapper {
  max-width: none;
}

/* set background color of the user’s correct/incorrect answer as grey for formulaV2*/
div .lrn_widget.lrn_formulaV2 .lrn_response_input.lrn_correct,
div .lrn_widget.lrn_formulaV2 .lrn_response_input.lrn_incorrect,
 
div .lrn_widget.lrn_chemistry .lrn_response_input.lrn_correct,
div .lrn_widget.lrn_chemistry .lrn_response_input.lrn_incorrect,
  
span.lrn_textinput.lrn-clozeformula-input.lrn_cloze_response.lrn_disabled.lrn_correct,
span.lrn_textinput.lrn-clozeformula-input.lrn_cloze_response.lrn_disabled.lrn_incorrect {
  border-left: none;
  background-color: #F0F0F0;
}

/* set background color of user's correct/incorrect answer as grey for chemistry cloze with image
 and eliminate the left border which is green/red by default */
.material-layout
  .lrn_widget.lrn_imageclozechemistry
  .lrn_imagecloze_container
  .lrn_imagecloze_response
  .lrn_textinput.lrn-clozeformula-input.lrn_incorrect,
.material-layout
  .lrn_widget.lrn_imageclozechemistry
  .lrn_imagecloze_container
  .lrn_imagecloze_response
  .lrn_textinput.lrn-clozeformula-input.lrn_correct {
  border-left: none;
  background: #F0F0F0;
}

.learnosity-response {
  height: auto !important;
  padding: 0rem !important;
  width: auto !important;
  margin-top: -10px;
  margin-bottom: -10px;
  margin-left: -5px;
  margin-right: -5px;
  overflow: hidden;
}

/* remove the check/cross sign at the correct/incorrect answer for formulaV2*/
div
  .lrn_widget.lrn_formulaV2
  .lrn_response_wrapper
  .lrn_response_input_wrapper
  .lrn_response_input.lrn_correct:before,
div
  .lrn_widget.lrn_formulaV2
  .lrn_response_wrapper
  .lrn_response_input_wrapper
  .lrn_response_input.lrn_incorrect:before,

span.lrn_textinput.lrn-clozeformula-input.lrn_cloze_response.lrn_disabled.lrn_correct:after,
span.lrn_textinput.lrn-clozeformula-input.lrn_cloze_response.lrn_disabled.lrn_incorrect:after,

div
  .lrn_widget.lrn_chemistry
  .lrn_response_wrapper
  .lrn_response_input_wrapper
.lrn_response_input.lrn_incorrect:before,
div
  .lrn_widget.lrn_chemistry
  .lrn_response_wrapper
  .lrn_response_input_wrapper
  .lrn_response_input.lrn_correct:before {
  content: none;
}

/* remove the background color in order to make “Correct answer” background separate from
the answer itself */
div .lrn_widget .lrn_correctAnswers {
  background-color: transparent;
  margin: 0;
  padding: 0;
}

/* set the font size of "Correct answers:" as <h3> to be consistent with other question types */
div .lrn_widget .lrn_correctAnswers > span {
  font-size: 1.17em;
  font-weight: bold;
}

/* set the background of the correct answer as grey */
div .lrn_widget .lrn_correctAnswerList {
  background-color: #F0F0F0;
  padding: 5px;
}

div .lrn_widget {
  margin-bottom: 0px;
}

/* remove the left border of the correct answer */
div .lrn_widget .lrn_correctAnswerList.lrn_single_correct_answer li {
  border-left: none;
}

/* correct answer visible */
.lrn_display_correct_answer_enabled .lrn_correctAnswers {
  margin-top: 1rem !important;
}

/* ensure keyboard shows up on top of modals */
.lrn_widget .lrn-ui-style-floating-keyboard {
  z-index: 3000 !important;
}
