.delete-objective-modal__errorMsg___13nJ4 {
  display: inline-block;
  color: #C84E50;
  font-size: 0.75rem;
  margin-left: 0.5rem;
}

.delete-objective-modal__errorIcon___1I_Cx {
  fill: #C84E50;
}
