.video-slider__videoSlider___3XkPQ .rc-slider-rail {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, .2);
  z-index: 1;
}

.video-slider__videoSlider___3XkPQ .rc-slider-track {
  position: absolute;
  background: #00abc7;
  opacity: 0.7;
  z-index: 2;
}

.video-slider__videoSlider___3XkPQ .rc-slider-handle {
  position: absolute;
}

.video-slider__videoSlider___3XkPQ .rc-slider-handle:focus {
  outline: 0;
}

.video-slider__videoSlider___3XkPQ .rc-slider-handle:focus + .rc-slider-mark {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  outline: 4px solid rgba(255, 255, 255, .9);
  outline-color: -webkit-focus-ring-color;
  outline-offset: -2px;
}
