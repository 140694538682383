.assessment-header__icons___Ces4u {
  width: 1rem;
  height: 1rem;
  display: block;
}

.assessment-header__iconEdit___1HK9N {
  fill: white;
}

.assessment-header__message___3SL9C {
  color: white;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.assessment-header__clickableMessage___3zqJW {
  cursor: pointer;
  text-decoration: underline;
}
