.index__lightMenu___fF1vB > button:focus {
  /*
    this overrides the default react toolbox :focus:not(:active) style
  */
  background-color: rgba(255, 255, 255, .7) !important;
}

.index__lightMenu___fF1vB > button:active {
  background-color: #FFFFFF !important;
}

.index__lightMenu___fF1vB > button:focus svg,
.index__lightMenu___fF1vB > button:active svg {
  fill: #21243C !important;
}

.index__darkMenu___1tT00 > button:focus {
  background-color: #727272 !important;
  color: #FFFFFF !important;
  outline-offset: 0.188rem;
}

.index__darkMenu___1tT00 > button:active {
  background-color: #484848;
  color: #FFFFFF !important;
}

.index__menuDivider___2-2ZL {
  margin: 0;
  background-color: #DDDDDD;
}
