.goal-update-snackbar__snackbar___1IBd0 {
    background-color: #3b3b3b;
    padding: 0.5rem 1rem;
    max-width: 37.5rem;
    left: 0;
    right: auto;
    margin: 0 0.5rem;
    border-radius: 0.25rem;
    z-index: 2000;
}

.goal-update-snackbar__active___3I7GO {
    -webkit-transform: translateY(-0.5rem) !important;
            transform: translateY(-0.5rem) !important;
}

.goal-update-snackbar__button___11ctC {
    margin: 0;
    padding: 0rem 0.5rem;
    color: #00A4B7 !important;
    font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.1em;
    display: inherit;
}

.goal-update-snackbar__button___11ctC:hover {
    background-color: #3b3b3b !important;
}

.goal-update-snackbar__label___2oMNX {
    color: #FFFFFF;
    font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    float: left;
    height: 2.25rem;
    line-height: 2.25rem;
}

.goal-update-snackbar__progressText___tZdcL {
    color: #ABABAB !important;
    float: right;
    text-align: right;
    padding-left: 1rem;
}

@media screen and (max-width: 16.5rem) {
    .goal-update-snackbar__snackbar___1IBd0 {
        border-radius: 0;
    }
}
