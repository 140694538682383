.question-preferences__buttonPadding___1Sd7W {
  margin-right: 0.75rem;
}

.question-preferences__field___2t2-s {
  display: inline-block;
  margin-right: 1.5rem;
}

.question-preferences__text___2YZws {
  font-size: 0.875rem;
}

.question-preferences__bold___HqjsZ {
  font-weight: bold;
}

.question-preferences__customPopoverCls___WInGU {
  max-width: unset !important;
  width: 19rem;
  margin-left: 11rem;
  margin-top: 0.5rem;
  -webkit-box-shadow: 0rem 0.125rem 0.5rem rgba(0, 0, 0, .24), 0rem 0rem 0.125rem rgba(0, 0, 0, .12);
          box-shadow: 0rem 0.125rem 0.5rem rgba(0, 0, 0, .24), 0rem 0rem 0.125rem rgba(0, 0, 0, .12);
  border-radius: 0.25rem;
}
