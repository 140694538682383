.nav-app-menu__caption___24TJR {
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  text-transform: capitalize;
  font-size: 1rem;
}

.nav-app-menu__menuIcon___1zDYr {
  /** Need to override the button style in material toolbox **/
  width: 2rem !important;
  height: 2rem !important;
}

.nav-app-menu__menuIcon-dark___2AdZe {
  fill: #FFFFFF !important;
}

.nav-app-menu__menuIcon-light___MEpWV {
  fill: #484848 !important;
}

.nav-app-menu__menuIconWrapper___3uOti button:focus:not(:active) {
  outline: 5px auto #5599d8;
  outline-offset: 0.188rem;
}

.nav-app-menu__menuItem___2aQTb {
  min-width: 8rem;
}

.nav-app-menu__billingLinkDetails___3Xl0O {
}

.nav-app-menu__accountMenu___3bt0S {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.nav-app-menu__accountMenuGlobalNav___2bltf {
  display: none;
}

@media screen and (min-width: 480px) {
  .nav-app-menu__accountMenu___3bt0S {
    -webkit-box-flex: initial;
    -webkit-flex-grow: initial;
        -ms-flex-positive: initial;
            flex-grow: initial;
  }

  .nav-app-menu__accountMenuGlobalNav___2bltf {
    display: inherit;
  }
}
