.index__togglePasswordIcon___1NHmv {
  fill: #727272;
  width: 1.7rem;
  height: 1.7rem;
}

.index__togglePassword___rDWZ0:hover {
  fill: #484848;
  cursor: pointer;
}

.index__togglePasswordActive___3RDET {
  fill: #484848;
}

.index__togglePasswordGroup___1yAk_ {
  width: 100%;
  position: relative;
}

.index__togglePassword___rDWZ0 {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -1rem;
  z-index: 1;
  border: none;
  background-color: transparent;
}

.index__togglePassword___rDWZ0:hover,
.index__togglePassword___rDWZ0:active {
  background-color: transparent !important;
}

.index__togglePasswordWithoutLabel___3RGFF {
  padding: 0;
  margin: 0 0 1.25rem 0;
}

.index__togglePasswordWithoutLabelButton___55EaO {
  margin-top: -1.625rem;
}

.index__togglePasswordInactiveWithoutLabel___2XLLc {
  background-color: #F8F8F8;
}
