.question-points__questionPoints___38fkz {
    position: relative;
}

.question-points__clickable___2zwIN {
    color: #008392;
    border-bottom: 1px solid #008392;
    cursor: pointer;
}

.question-points__weight___R-Fp2 {
    padding-left: 0.25rem;
}
