body {
  padding: 0;
  margin: 0;
  background: #F8F8F8;
  font-size: 16px;
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  color: #484848;
  font-weight: 400;
}

p {
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
}

h1 {
  font-size: 45px;
  line-height: 48px;
  padding: 0;
  margin: 0;
  color: #484848;
  font-weight: 400;
}

h2 {
  font-size: 34px;
  line-height: 40px;
  padding: 0;
  margin: 0;
  color: #484848;
  font-weight: 400;
}

h3 {
  font-size: 24px;
  font-weight: 650;
  line-height: 32px;
  padding: 0;
  margin: 0;
  color: #484848;
  font-weight: 600;
}

h4 {
  font-size: 20px;
  line-height: 28px;
  padding: 0;
  margin: 0;
  font-weight: normal;
  color: #484848;
  font-weight: 400;
}

h5 {
  font-size: 16px;
  line-height: 24px;
  padding: 0;
  margin: 0;
  color: #727272;
  font-weight: 600;
}

.material-base__heading1___3aokI {
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
}

.material-base__heading2___1WprT {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
}

.material-base__heading3___92OP1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.material-base__heading4___1icxG {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.material-base__heading5___3l1F3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.light body,
.light p,
.light h1,
.light h2,
.light h3,
.light h4,
.light h5 {
  color: #484848;
}

.dark body,
.dark p,
.dark h1,
.dark h2,
.dark h3,
.dark h4,
.dark h5 {
  color: #FFFFFF;
}

.caption {
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #727272;
  font-weight: 600;
}

.dark .caption {
  color: rgba(255, 255, 255, .7);
}

.caption-small {
  font-size: 12px;
  line-height: 18px;
  color: #727272;
}

.dark .caption-small {
  color: rgba(255, 255, 255, .7);
}

.icon {
  fill: #000000;
  width: 1em;
  height: 1em;
}

.material-base__textLinkFocus___31slL {
  position: relative;
  display: inline-block;
  margin-top: 0.281rem;
}

.material-base__textLinkFocus___31slL:focus {
  outline-offset: 0.18rem;
}

.material-base__textLinkFocus___31slL::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  height: 0.0625rem;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  background: #008392; /* override this */
}

.material-base__textLinkFocus___31slL:active::after {
  background: #004b54; /* override this */
}

[data-whatintent='keyboard'] *:focus {
  outline: 0.3125rem auto #5599d8;
}

[data-whatintent='mouse'] *:active {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

/* from: https://allyjs.io/tutorials/hiding-elements.html */
.material-base__visuallyhidden___1g5zR {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;

  -webkit-clip-path: inset(100%);

          clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

/**
 * Overide Mathajx inline style that causes
 * weird formatting issues like line breaks.
 */
.MathJax:focus,
body :focus .MathJax {
  display: inline !important;
}
