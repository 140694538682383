.atom-variation-header__header___1zS11 {
  -webkit-box-flex: 2;
  -webkit-flex: 2 0 0;
      -ms-flex: 2 0 0px;
          flex: 2 0 0;
}
.atom-variation-header__handlebar___A4A4h {
  position: absolute;
  left: 50%;
  margin-left: 0.75rem;
}

.atom-variation-header__handlebar___A4A4h::before,
.atom-variation-header__handlebar___A4A4h::after {
  content: '';
  position: relative;
  display: block;
  width: 16px;
  width: 1rem;
  height: 2px;
  height: 0.125rem;
  background-color: #484848;
}

.atom-variation-header__handlebar___A4A4h::before {
  bottom: 1px;
  bottom: 0.0625rem;
}

.atom-variation-header__handlebar___A4A4h::after {
  top: 1px;
  top: 0.0625rem;
}
