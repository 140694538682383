.atom-attribution__hiddenAttribution___3idfS {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.atom-attribution__compoundAttribution___3yQpr {
  color: #727272;
  font-size: 12px;
  margin-top: 0.625rem;
}

.atom-attribution__compoundAttribution___3yQpr a {
  color: #727272;
  text-decoration: none;
}

.atom-attribution__compoundAttribution___3yQpr {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
}
