.hero-dates__dateIcon___2Ph-g {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1rem;
  position: relative;
  top: 0.125rem;
}

.hero-dates__dates___2u7yg {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1.5rem;
}

.hero-dates__startDate___30wGE {
  margin-bottom: 0.5rem;
}

.hero-dates__bold___WTQ8U {
  font-weight: bold;
}
