.question-count-warnings__warningMessageList___121Tw {
  padding: 0 0 0 1rem;
  margin: 0;
  text-align: left;
}

.question-count-warnings__warningMessageListItem___CFxao {
  font-size: 0.75rem;
  line-height: 1rem;
}

.question-count-warnings__warningListItemMargin___6iwmY:not(:last-child) {
  margin-bottom: 0.25rem;
}

.question-count-warnings__warningMessage___30Hvj {
  position: relative;
  left: -0.3125rem;
}

@-moz-document url-prefix() {
  .question-count-warnings__warningMessage___30Hvj {
    left: 0;
  }
}
