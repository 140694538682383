/* for compatibility with standard layout */
.nav-app-login-bar__button___3JbuD,
.nav-app-login-bar__dismiss___EhKhY {
  height: auto;
  line-height: 24px;
  padding: 0;
}

.nav-app-login-bar__button___3JbuD:hover,
.nav-app-login-bar__dismiss___EhKhY:hover {
  background-color: inherit !important;
}

.learnpage .nav-app-login-bar__loginBar___1TYjJ {
  margin-top: 69px;
}

.teach-page .nav-app-login-bar__loginBar___1TYjJ {
  margin-top: 75px;
}

/* end */

.nav-app-login-bar__loginBar___1TYjJ {
  padding: 16px;
  color: white;
  background-color: #7882DC;
  line-height: 24px;
}

.nav-app-login-bar__loginBar___1TYjJ p {
  margin: 0;
  font-size: 12px;
  white-space: normal;
  text-align: left;
}

.nav-app-login-bar__loginBarContents___uSzeq {
  position: relative;
  max-width: 960px;
  margin: 0 auto;
}

.nav-app-login-bar__loginBarHeader___3UyfZ {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.nav-app-login-bar__email___274MZ {
  font-size: 16px;
  font-weight: 600;
}

.nav-app-login-bar__dismiss___EhKhY {
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
  color: white;
  font-size: 14px;
}

.nav-app-login-bar__dismiss___EhKhY:hover,
.nav-app-login-bar__dismiss___EhKhY:active,
.nav-app-login-bar__dismiss___EhKhY:focus {
  text-decoration: underline;
}

.nav-app-login-bar__detailsIcon___3jBSU {
  position: relative;
  top: 1px;
  left: 4px;
  fill: white !important;
  width: 12px !important;
  height: 12px !important;
}

.nav-app-login-bar__details___2HdW6 {
  padding-top: 8px;
  font-family: "Monaco", "Consolas", "Lucida Console", monospace;
}
