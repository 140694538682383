.sticky-element__sticky___cFTnT {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 1;
  z-index: 1000;
  -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, .16);
          box-shadow: 0px 2px 5px rgba(0, 0, 0, .16);
  -webkit-transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1),
    transform 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1),
    transform 0.35s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-delay: 0.07s;
          transition-delay: 0.07s;
}
