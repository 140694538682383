.new-instructor-reset-password__almostThere___1TsVG {
  margin-top: -3rem;
  background: white;
  position: relative;
}

.new-instructor-reset-password__subHeader___6ubaY {
  line-height: 1.5rem;
}

.new-instructor-reset-password__button___2JTAg {
  margin-top: 1rem;
}
