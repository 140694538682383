.icon__size-small___2cpVR {
  width: 0.75rem;
  height: 0.75rem;
}

.icon__size-medium___lCiPT {
  width: 1rem;
  height: 1rem;
}

.icon__size-medlarge___tJ7Yg {
  width: 1.2rem;
  height: 1.2rem;
}

.icon__size-large___2ciRQ {
  width: 1.5rem;
  height: 1.5rem;
}

.icon__size-xlarge___i1aGz {
  width: 2rem;
  height: 2rem;
}

.icon__size-xxlarge___uCRtL {
  width: 2.5rem;
  height: 2.5rem;
}

.icon__size-huge___1_1wn {
  width: 4rem;
  height: 4rem;
}

.icon__context-background-light___2K57r {
  fill: #484848;
}

.icon__context-background-dark___qJR3Z {
  fill: #FFFFFF;
}

.icon__disabled-true___2_MKX {
  cursor: not-allowed;
  fill: #ABABAB;
}
