/* do not display the desktop button on small screens */
@media screen and (max-width: 599px) {
  .feedback-menu__desktopButtonDisplay___34EIg {
    display: none;
  }
}

/* do not display the desktop button in assignment browse */
.assignment-browse-content .feedback-menu__desktopButtonDisplay___34EIg {
  display: none;
}

/* do not display desktop button on the sequence view page */
.sequence-view-page .feedback-menu__desktopButtonDisplay___34EIg {
  display: none;
}
/* do not display desktop button on the sequence preview page */
.sequence-preview-page .feedback-menu__desktopButtonDisplay___34EIg {
  display: none;
}

@media screen and (min-width: 600px) {
  /*
    reserve overflow icon for mobile view in:
    assessment flow, learn , user-history-answer-modal, activity-modal
  */
  .teach-page .feedback-menu__overflowIconDisplay___2VPfN,
  .learn-flow-body .feedback-menu__overflowIconDisplay___2VPfN,
  .show-scaffolds-modal .feedback-menu__overflowIconDisplay___2VPfN,
  .user-history-answer-modal .feedback-menu__overflowIconDisplay___2VPfN,
  .assessment-questions-modal .feedback-menu__overflowIconDisplay___2VPfN,
  .activity-modal .feedback-menu__overflowIconDisplay___2VPfN,
  .lo-question-history-modal .feedback-menu__overflowIconDisplay___2VPfN,
  .student-answer-modal .feedback-menu__overflowIconDisplay___2VPfN,
  .assessment-flow .feedback-menu__overflowIconDisplay___2VPfN,
  .question-stats-breakdown-modal .feedback-menu__overflowIconDisplay___2VPfN,
  
  [data-contentfeedback~="use-mobile-overflow"] .feedback-menu__overflowIconDisplay___2VPfN {
    display: none;
  }
}

.feedback-menu__feedbackIcon___2S4UH {
  display: block;
  margin-top: 0.125rem;
  margin-right: 0.125rem;
}
