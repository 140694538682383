.index__errorMessage___1_6CZ {
  font-size: 0.75rem;
  color: #C84E50;
  margin: 0.25rem 0;
}

.index__warningMessageBox___1BT18 {
  background-color: #F5F5FF;
  border: 1px solid #7882DC;
  border-radius: 0.25rem;
  padding: 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: top;
  -webkit-align-items: top;
      -ms-flex-align: top;
          align-items: top;
}

.index__warningMessage___11PkL {
  line-height: 1.5rem;
  color: #484848;
  font-size: 0.875rem;
}

.index__warningMessageIcon___GidHQ {
  fill: #636BB5;
  margin-right: 0.5rem;
}

.index__infoMessageBox___Kv7Xd {
  background-color: #FEFAF0;
  border: 1px solid #F5CB5C;
  border-radius: 0.25rem;
  padding: 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: top;
  -webkit-align-items: top;
      -ms-flex-align: top;
          align-items: top;
}

.index__infoMessage___1owoP {
  line-height: 1.5rem;
  color: #705d2a;
  font-size: 0.875rem;
}

.index__infoMessageIcon___fPkmH {
  fill: #9c823b;
  margin-right: 0.5rem;
}
