.save-status-indicator__errorIcon___1GmBL {
  fill: #C84E50;
  margin-right: 0.5rem;
}

.save-status-indicator__errorMsg___6q-o7 {
  color: #C84E50;
}

.save-status-indicator__loadingIcon___28M1z {
  fill: #ABABAB;
}
