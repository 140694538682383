.popover__popoverOverlay___ilK4Y,
.popover__popover___3NY2r {
  position: fixed;
  z-index: 2000;
}

.popover__popoverOverlay___ilK4Y {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
}

.popover__popover___3NY2r {
  max-width: 16.25rem;
  min-width: 12.5rem;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.15);
}

.popover__isOpen___4ezfr {
  display: block;
}
