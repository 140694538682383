.box__default___27jt2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

/** alignment **/

.box__alignItems-start___3mA1M {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.box__alignItems-center___1DBM4 {
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.box__alignItems-end___3NUi2 {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.box__alignItems-baseline___3dkrL {
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}

.box__alignItems-stretch___2hF9e {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.box__align-self-start___1qVO9 {
  -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
          align-self: flex-start;
}

.box__align-self-center___2JBwB {
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
}

.box__align-self-end___1Xh1J {
  -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
          align-self: flex-end;
}

.box__align-self-stretch___15JOc {
  -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
          align-self: stretch;
}

.box__direction-row___alYkD {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
}

.box__direction-column___2F1z_ {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

.box__direction-row___alYkD.box__reverse-true___Eephq {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.box__direction-column___2F1z_.box__reverse-true___Eephq {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

/** flex-grow, flex-shrink **/

.box__flex-true___2sRxs {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  /* need to fix flexbox issue with min-width: auto for some tags */
  min-width: 0;
  overflow: auto;
}

.box__flex-false___ou6oo {
  display: block;
  -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
          align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}

.box__flex-grow___bCcmp {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  /* need to fix flexbox issue with min-width: auto for some tags */
  min-width: 0;
}

.box__flex-shrink___uZrRj {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
}

.box__flex-expand___3FR_W {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

/** flex-full **/
.box__full-true___2shjB {
  position: relative;
  max-width: 100%;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  overflow: auto;
}

.box__full-horizontal___28tJw {
  max-width: 100%;
  width: 100vw;
}

.box__full-vertical___1Nkst {
  height: 100vh;
  max-height: 100%;
  overflow: auto;
}

.box__full-page___3xrJ3 {
  max-width: 960px;
  width: 100%;
}

/** justify **/

.box__justify-start___3k2aw {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.box__justify-center___3HY-H {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.box__justify-between___jW6OW {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.box__justify-around___1fIE_ {
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}

.box__justify-end___3a2af {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

/** margin, padding **/

.box__margin-none___2raf4 {
  margin: 0;
}

.box__margin-horizontal-none___AsGmm {
  margin-left: 0;
  margin-right: 0;
}

.box__margin-vertical-none___2HS_h {
  margin-top: 0;
  margin-bottom: 0;
}

.box__margin-top-none___3i8nk {
  margin-top: 0;
}

.box__margin-right-none___1MBq6 {
  margin-right: 0;
}

.box__margin-bottom-none___1UUSB {
  margin-bottom: 0;
}

.box__margin-left-none___C6DXj {
  margin-left: 0;
}

.box__margin-top-auto___1HgCQ {
  margin-top: auto;
}

.box__margin-right-auto___1q8qu {
  margin-right: auto;
}

.box__margin-bottom-auto___3KfHi {
  margin-bottom: auto;
}

.box__margin-left-auto____QS01 {
  margin-left: auto;
}

.box__margin-small___3zry3 {
  margin: 0.5rem;
}

.box__margin-vertical-small___3uCJY {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.box__margin-horizontal-small___3mJ7c {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.box__margin-vertical-none___2HS_h {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.box__margin-top-small___37G_p {
  margin-top: 0.5rem;
}

.box__margin-right-small___2xigg {
  margin-right: 0.5rem;
}

.box__margin-bottom-small___2bPe8 {
  margin-bottom: 0.5rem;
}

.box__margin-left-small___5Ck-H {
  margin-left: 0.5rem;
}

.box__margin-medium___3V8Jc {
  margin: 1rem;
}

.box__margin-horizontal-medium___2kONj {
  margin-left: 1rem;
  margin-right: 1rem;
}

.box__margin-vertical-medium___3d9Yj {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.box__margin-top-medium___3236W {
  margin-top: 1rem;
}

.box__margin-right-medium___1wFft {
  margin-right: 1rem;
}

.box__margin-bottom-medium___1ikAD {
  margin-bottom: 1rem;
}

.box__margin-left-medium___y7uX6 {
  margin-left: 1rem;
}

.box__margin-large___1QGCz {
  margin: 2rem;
}

.box__margin-horizontal-large___3uNvv {
  margin-left: 2rem;
  margin-right: 2rem;
}

.box__margin-vertical-large___1_1TJ {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.box__margin-top-large___wo1vD {
  margin-top: 2rem;
}

.box__margin-right-large___3kTHn {
  margin-right: 2rem;
}

.box__margin-bottom-large___1VBrt {
  margin-bottom: 2rem;
}

.box__margin-left-large___1jgef {
  margin-left: 2rem;
}

.box__margin-xlarge___1R4Ix {
  margin: 3.5rem;
}

.box__margin-horizontal-xlarge___1di_2 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}

.box__margin-vertical-xlarge___23gG1 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.box__margin-top-xlarge___2g7dq {
  margin-top: 3.5rem;
}

.box__margin-right-xlarge___1OzhR {
  margin-right: 3.5rem;
}

.box__margin-bottom-xlarge___15xGq {
  margin-bottom: 3.5rem;
}

.box__margin-left-xlarge___3WeJh {
  margin-left: 3.5rem;
}

.box__margin-huge___3STUf {
  margin: 5rem;
}

.box__margin-horizontal-huge___3dQIH {
  margin-left: 5rem;
  margin-right: 5rem;
}

.box__margin-vertical-huge___1Ud7I {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.box__margin-top-huge___2JaqP {
  margin-top: 5rem;
}

.box__margin-right-huge___TGjJR {
  margin-right: 5rem;
}

.box__margin-bottom-huge___22_cg {
  margin-bottom: 5rem;
}

.box__margin-left-huge___3aMa9 {
  margin-left: 5rem;
}

.box__pad-none___2-a4e {
  padding: 0;
}

.box__pad-horizontal-none___3pmN2 {
  padding-left: 0;
  padding-right: 0;
}

.box__pad-vertical-none___2rtGK {
  padding-top: 0;
  padding-bottom: 0;
}

.box__pad-top-none___1wF_z {
  padding-top: 0;
}

.box__pad-right-none___34Aqs {
  padding-right: 0;
}

.box__pad-bottom-none___1ZZ0k {
  padding-bottom: 0;
}

.box__pad-left-none___14bDp {
  padding-left: 0;
}

.box__pad-small___1uGoX {
  padding: 0.5rem;
}

.box__pad-horizontal-small___2OEiB {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.box__pad-vertical-small___tuVX4 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.box__pad-top-small___1Ey03 {
  padding-top: 0.5rem;
}

.box__pad-right-small___1lN7J {
  padding-right: 0.5rem;
}

.box__pad-bottom-small___t0HSN {
  padding-bottom: 0.5rem;
}

.box__pad-left-small___310w0 {
  padding-left: 0.5rem;
}

.box__pad-medium___34lXC {
  padding: 1rem;
}

.box__pad-horizontal-medium___1iRnf {
  padding-left: 1rem;
  padding-right: 1rem;
}

.box__pad-vertical-medium___2GhO0 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.box__pad-top-medium___1GvyG {
  padding-top: 1rem;
}

.box__pad-right-medium___-Ajmg {
  padding-right: 1rem;
}

.box__pad-bottom-medium___3HFlP {
  padding-bottom: 1rem;
}

.box__pad-left-medium___2J3gv {
  padding-left: 1rem;
}

.box__pad-large___2Tpvd {
  padding: 2rem;
}

.box__pad-horizontal-large___9fQmj {
  padding-left: 2rem;
  padding-right: 2rem;
}

.box__pad-vertical-large___3LQnQ {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.box__pad-top-large___3aYC8 {
  padding-top: 2rem;
}

.box__pad-right-large___3C6aJ {
  padding-right: 2rem;
}

.box__pad-bottom-large___3bzGD {
  padding-bottom: 2rem;
}

.box__pad-left-large___34Cfz {
  padding-left: 2rem;
}

.box__pad-xlarge___1Flg9 {
  padding: 3.5rem;
}

.box__pad-horizontal-xlarge___2wVsN {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.box__pad-vertical-xlarge___1HMM3 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.box__pad-top-xlarge___2XgCu {
  padding-top: 3.5rem;
}

.box__pad-right-xlarge___JSYZy {
  padding-right: 3.5rem;
}

.box__pad-bottom-xlarge___1eiuo {
  padding-bottom: 3.5rem;
}

.box__pad-left-xlarge___nS6dN {
  padding-left: 3.5rem;
}

.box__pad-huge___3d0VF {
  padding: 5rem;
}

.box__pad-horizontal-huge___3PvTA {
  padding-left: 5rem;
  padding-right: 5rem;
}

.box__pad-vertical-huge___3CYgb {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.box__pad-top-huge___fv9DS {
  padding-top: 5rem;
}

.box__pad-right-huge___DtsC3 {
  padding-right: 5rem;
}

.box__pad-bottom-huge___1C4B_ {
  padding-bottom: 5rem;
}

.box__pad-left-huge___3MBGQ {
  padding-left: 5rem;
}

.box__direction-row___alYkD.box__pad-between-small___3HgIA > *:not(:last-child) {
  margin-right: 0.5rem;
}

.box__direction-row___alYkD.box__pad-between-medium___f0E2O > *:not(:last-child) {
  margin-right: 1rem;
}

.box__direction-row___alYkD.box__pad-between-large___3k9uz > *:not(:last-child) {
  margin-right: 2rem;
}

.box__direction-row___alYkD.box__pad-between-xlarge___3xbhT > *:not(:last-child) {
  margin-right: 3.5rem;
}

.box__direction-row___alYkD.box__pad-between-huge___17MOZ > *:not(:last-child) {
  margin-right: 5rem;
}

.box__direction-column___2F1z_.box__pad-between-small___3HgIA > *:not(:last-child) {
  margin-bottom: 0.5rem;
}

.box__direction-column___2F1z_.box__pad-between-medium___f0E2O > *:not(:last-child) {
  margin-bottom: 1rem;
}

.box__direction-column___2F1z_.box__pad-between-large___3k9uz > *:not(:last-child) {
  margin-bottom: 2rem;
}

.box__direction-column___2F1z_.box__pad-between-xlarge___3xbhT > *:not(:last-child) {
  margin-bottom: 3.5rem;
}

.box__direction-column___2F1z_.box__pad-between-huge___17MOZ > *:not(:last-child) {
  margin-bottom: 5rem;
}

/** text alignment **/

.box__text-align-left___3LttS {
  text-align: left;
}

.box__text-align-center___7_vCp {
  text-align: center;
}

.box__text-align-right___2hGy8 {
  text-align: right;
}

/** wrapping **/

.box__wrap-true___FLOKn {
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.box__wrap-false___tv7CV {
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

/** separator **/

.box__separator-top___2wQ75 {
  border-top: 1px solid #DDDDDD;
}

.box__separator-bottom___ADekP {
  border-bottom: 1px solid #DDDDDD;
}

.box__separator-left___A7Jyr {
  border-left: 1px solid #DDDDDD;
}

.box__separator-right___2X1cL {
  border-right: 1px solid #DDDDDD;
}

.box__separator-horizontal___2lB_F {
  border-top: 1px solid #DDDDDD;
  border-bottom: 1px solid #DDDDDD;
}

.box__separator-vertical___3d1co {
  border-left: 1px solid #DDDDDD;
  border-right: 1px solid #DDDDDD;
}

.box__separator-all___23Qek {
  border: 1px solid #DDDDDD;
}

.box__separator-none___2mJUh {
  border: none;
}

.box__direction-row___alYkD.box__separator-between___1O_B3 > *:not(:last-child) {
  border-right: 1px solid #DDDDDD;
}

.box__direction-column___2F1z_.box__separator-between___1O_B3 > *:not(:last-child) {
  border-bottom: 1px solid #DDDDDD;
}

/* rounded border */
.box__rounded-border-true___16_vb {
  border-radius: 0.25rem;
}

/** responsive **/

@media screen and (max-width: 480px) {
  .box__full-true___2shjB.box__responsive-true___2vfjq {
    min-height: 100vh;
    height: 100%;
  }

  .box__full-vertical___1Nkst.box__responsive-true___2vfjq {
    min-height: 100vh;
    height: 100%;
  }

  .box__direction-row___alYkD.box__responsive-true___2vfjq {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .box__reverse-true___Eephq.box__responsive-true___2vfjq {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .box__responsive-true___2vfjq {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }

  .box__responsive-true___2vfjq.box__direction-row___alYkD.box__pad-between-small___3HgIA > *:not(:last-child) {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .box__responsive-true___2vfjq.box__direction-row___alYkD.box__pad-between-medium___f0E2O > *:not(:last-child) {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .box__responsive-true___2vfjq.box__direction-row___alYkD.box__pad-between-large___3k9uz > *:not(:last-child) {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .box__responsive-true___2vfjq.box__direction-row___alYkD.box__separator-between___1O_B3 > *:not(:last-child) {
    border-bottom: 1px solid #DDDDDD;
  }
}
