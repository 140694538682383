.hero__heroWrapper___1mVV5 {
  width: 100%;
  padding-bottom: 2.5rem;
}

.hero__titleWrapper___2NSoz {
  width: 100%;
}

.hero__gearIcon___3NByU {
  width: 1rem;
  height: 1rem;
}

@media (min-width: 480px) {
  .hero__titleWrapper___2NSoz {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
