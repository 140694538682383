.search-input__searchForBox___2Pbsi {
  margin-top: -1rem;
  -webkit-box-shadow: 0 0.125rem 0.3125rem rgba(0, 0, 0, .16);
          box-shadow: 0 0.125rem 0.3125rem rgba(0, 0, 0, .16);
  cursor: pointer;
}

.search-input__searchForBoxFocus___oLzhe,
.search-input__searchForBox___2Pbsi:hover {
  background: #F8F8F8;
}

.search-input__pressEnter___1Mwwg {
  color: #ABABAB;
}

.search-input__inputWrapper___1tC5b {
  position: relative;
  width: 100%;
}

.search-input__inputField___E28NG {
  width: 100% !important;
}

.search-input__searchIcon___38mkf {
  fill: #008392;
}

.search-input__clearSearchIcon___ilqrL {
  position: absolute;
  right: 1rem;
  cursor: pointer;
  fill: #727272;
}
