.question-heading__questionHeading___33x6l {
  letter-spacing: 0.0625rem;
}

.question-heading__questionHeadingWrapper___2X43e {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
}

.question-heading__headingSeparator___3PL0J {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
