.page__headerContainer-dark___3Soj8 {
  padding: 24px 16px 0;
  background: #21243C;
  position: relative;
  z-index: 150;
}

.page__headerContainer-light___20Pqr {
  padding: 24px 16px 0;
  background: #FFFFFF;
  position: relative;
  z-index: 150;
}

.page__wrapper___cV-L0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.page__header___3GUS3 {
  width: 100%;
  max-width: 960px;
}

.page__header___3GUS3 p {
  margin: 0;
}

.page__navigationContainer___1U6cp {
  padding: 0;
}

.page__navigationContainer-dark___ra_Rv {
  background: #21243C;
}

.page__navigationContainer-light___1ecG2 {
  background: #FFFFFF;
}

.page__navigationContainer___1U6cp .page__navigation___stdad {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 100%;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
}

.page__navigation___stdad {
  max-width: 960px;
  margin: 0 auto;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.page__pointer-dark___Sado- {
  background-color: #FFFFFF;
}

.page__pointer-light___11KeL {
  background-color: #484848;
}

.page__child___2M9Bq {
  max-width: 960px;
  margin: 0 auto;
  padding: 2.5rem 0;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: visible !important; /* Override react-toolbox, otherwise review assignments banner is hidden */
}

.page__noPadding___2rYRq {
  padding: 0;
}

.page__label___1A4Hk {
  padding: 12px;
  margin: 4px;
  font-weight: 600;
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.page__navigationContainer-dark___ra_Rv .page__label___1A4Hk {
  color: rgba(255, 255, 255, 0.5);
}

.page__navigationContainer-dark___ra_Rv .page__label___1A4Hk.page__focus___2iDOx,
.page__navigationContainer-dark___ra_Rv .page__label___1A4Hk:focus {
  outline: 5px auto #FFFFFF;
}

.page__navigationContainer-dark___ra_Rv .page__activeLabel___xpiS6 {
  color: #FFFFFF !important; /* needed to override label class, since both are applied */
}

.page__navigationContainer-light___1ecG2 .page__label___1A4Hk {
  color: #727272;
}

.page__navigationContainer-light___1ecG2 .page__label___1A4Hk.page__focus___2iDOx,
.page__navigationContainer-light___1ecG2 .page__label___1A4Hk:focus {
  outline: 5px auto #484848;
}

.page__navigationContainer-light___1ecG2 .page__activeLabel___xpiS6 {
  color: #484848 !important; /* needed to override label class, since both are applied */
}

[data-whatintent='mouse'] .page__label___1A4Hk:focus,
[data-whatintent='mouse'] .page__label___1A4Hk.page__focus___2iDOx {
  outline: none;
}
