.third-party-cookie-modal__modalTitle___CFGM4 {
  font-size: 2rem;
  line-height: 40px;
  margin: 0;
  color: #484848;
  font-weight: 400;
  padding: 0 0 1.5rem 0;
}

.third-party-cookie-modal__helpLink___3O0ms {
  color: #008392;
  text-decoration: none;
}
