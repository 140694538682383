.spinner-atom__spinners___3MLSn {
  width: 200px;
  height: 200px;
  position: relative;
  margin: 0 auto;
}
.spinner-atom__spinner1___137ex,
.spinner-atom__spinner2___35nvi,
.spinner-atom__spinner3___2ToGf {
  position: absolute;
  left: -4px;
  top: -2px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.spinner-atom__spinner1___137ex {
  border-left: 5px #23acbf solid;
  border-top: 10px transparent solid;
  border-right: 5px #23acbf solid;
  border-bottom: 0px #23acbf solid;
  -webkit-animation: spinner-atom__ani-1___2-c_E 1.4s linear infinite;
          animation: spinner-atom__ani-1___2-c_E 1.4s linear infinite;
}
.spinner-atom__spinner2___35nvi {
  border-left: 5px #257590 solid;
  border-top: 10px transparent solid;
  border-right: 5px #257590 solid;
  border-bottom: 0px #257590 solid;
  -webkit-animation: spinner-atom__ani-2___14izV 1.4s linear infinite;
          animation: spinner-atom__ani-2___14izV 1.4s linear infinite;
}
.spinner-atom__spinner3___2ToGf {
  border-left: 5px #243954 solid;
  border-top: 10px transparent solid;
  border-right: 5px #243954 solid;
  border-bottom: 0px #243954 solid;
  -webkit-animation: spinner-atom__ani-3___1-ACm 1.4s linear infinite;
          animation: spinner-atom__ani-3___1-ACm 1.4s linear infinite;
}
.spinner-atom__nucleus___2RJLZ {
  position: absolute;
  width: 10%;
  height: 10%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

  background-color: #243954;
  border-radius: 50%;
}

@-webkit-keyframes spinner-atom__ani-1___2-c_E {
  from {
    -webkit-transform: rotateZ(120deg) rotateX(70deg) rotateZ(0deg);
            transform: rotateZ(120deg) rotateX(70deg) rotateZ(0deg);
  }
  to {
    -webkit-transform: rotateZ(120deg) rotateX(70deg) rotateZ(360deg);
            transform: rotateZ(120deg) rotateX(70deg) rotateZ(360deg);
  }
}

@keyframes spinner-atom__ani-1___2-c_E {
  from {
    -webkit-transform: rotateZ(120deg) rotateX(70deg) rotateZ(0deg);
            transform: rotateZ(120deg) rotateX(70deg) rotateZ(0deg);
  }
  to {
    -webkit-transform: rotateZ(120deg) rotateX(70deg) rotateZ(360deg);
            transform: rotateZ(120deg) rotateX(70deg) rotateZ(360deg);
  }
}
@-webkit-keyframes spinner-atom__ani-2___14izV {
  from {
    -webkit-transform: rotateZ(240deg) rotateX(70deg) rotateZ(0deg);
            transform: rotateZ(240deg) rotateX(70deg) rotateZ(0deg);
  }
  to {
    -webkit-transform: rotateZ(240deg) rotateX(70deg) rotateZ(360deg);
            transform: rotateZ(240deg) rotateX(70deg) rotateZ(360deg);
  }
}
@keyframes spinner-atom__ani-2___14izV {
  from {
    -webkit-transform: rotateZ(240deg) rotateX(70deg) rotateZ(0deg);
            transform: rotateZ(240deg) rotateX(70deg) rotateZ(0deg);
  }
  to {
    -webkit-transform: rotateZ(240deg) rotateX(70deg) rotateZ(360deg);
            transform: rotateZ(240deg) rotateX(70deg) rotateZ(360deg);
  }
}
@-webkit-keyframes spinner-atom__ani-3___1-ACm {
  from {
    -webkit-transform: rotateZ(360deg) rotateX(70deg) rotateZ(0deg);
            transform: rotateZ(360deg) rotateX(70deg) rotateZ(0deg);
  }
  to {
    -webkit-transform: rotateZ(360deg) rotateX(70deg) rotateZ(360deg);
            transform: rotateZ(360deg) rotateX(70deg) rotateZ(360deg);
  }
}
@keyframes spinner-atom__ani-3___1-ACm {
  from {
    -webkit-transform: rotateZ(360deg) rotateX(70deg) rotateZ(0deg);
            transform: rotateZ(360deg) rotateX(70deg) rotateZ(0deg);
  }
  to {
    -webkit-transform: rotateZ(360deg) rotateX(70deg) rotateZ(360deg);
            transform: rotateZ(360deg) rotateX(70deg) rotateZ(360deg);
  }
}

.spinner-atom__spinner___2XDgc {
  width: 12rem;
}
