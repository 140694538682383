.modal-body__modalBody___1q8Hq {
  max-width: 794px;
  margin: 0 auto;
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  color: #000000;
}

.modal-body__modalBody___1q8Hq p {
  font-size: 16px;
  color: #484848;
  padding-bottom: 16px;
}
