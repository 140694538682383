.google-oauth-button-material__googleIcon___1sIrn {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.google-oauth-button-material__buttonText___5DDeU {
  color: #484848;
  font-size: 14px;
  font-weight: 600;
}

.google-oauth-button-material__button___35XR2 {
  font-size: 14px;
  height: 2.85em;
  width: 100%;
  background-color: #FFFFFF;
  padding: 0 8px;
  border: 1px solid #484848;
  border-radius: 2px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  text-decoration: none;
}

.google-oauth-button-material__button___35XR2 span[data-react-toolbox='font-icon'] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
