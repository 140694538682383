.assessment-question-actions__actionState___2_Cd2 {
  -webkit-box-flex: 3;
  -webkit-flex: 3 0 0;
      -ms-flex: 3 0 0px;
          flex: 3 0 0;
  padding-top: 0.5rem;
}

.assessment-question-actions__actionButton___1xutT {
  margin: 0 1rem;
}

.assessment-question-actions__changeQuestionIcon___2WXxD {
  margin-top: 0.75rem;
}

.assessment-question-actions__addPoolQuestionBtn___2RzRj{
  margin-bottom: 0.5rem;
}

.assessment-question-actions__navigation___34Eqx{
  margin-bottom: -0.25rem;
  margin-top: -0.75rem;
  -webkit-box-align: start;
  -webkit-align-items: start;
      -ms-flex-align: start;
          align-items: start;
  -webkit-box-flex: 2;
  -webkit-flex: 2 0 0;
      -ms-flex: 2 0 0px;
          flex: 2 0 0;
}

.assessment-question-actions__removeIcon___2cL6L {
  display: block;
  fill: #727272;
}