.custom-questions-modal__cardLinkGroup___3RG4F {
  margin-top: 2rem;
}

.custom-questions-modal__cancelButton___xFfgQ {
  margin-right: 1rem;
}

.custom-questions-modal__backButton___2mqij {
  cursor: pointer;
  margin-right: 1rem;
}

@media (min-width: 960px) {
  .custom-questions-modal__backButton___2mqij {
    position: relative;
    right: 1rem;
    margin-right: 0;
  }
}
