@-webkit-keyframes placeholder__shimmer___2ShtJ {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

@keyframes placeholder__shimmer___2ShtJ {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.placeholder__placeholder___1bwQ_ {
  display: block;
  width: 100%;
  max-width: 10rem;
  background: -webkit-gradient(
    linear,
    left top, right top,
    color-stop(8%, rgba(204, 204, 204, .4)),
    color-stop(18%, rgba(204, 204, 204, .6)),
    color-stop(33%, rgba(204, 204, 204, .4))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(204, 204, 204, .4) 8%,
    rgba(204, 204, 204, .6) 18%,
    rgba(204, 204, 204, .4) 33%
  );
  background: linear-gradient(
    to right,
    rgba(204, 204, 204, .4) 8%,
    rgba(204, 204, 204, .6) 18%,
    rgba(204, 204, 204, .4) 33%
  );
  background-size: 800px 104px;
  -webkit-animation-name: placeholder__shimmer___2ShtJ;
          animation-name: placeholder__shimmer___2ShtJ;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  border-radius: 2px;
}
