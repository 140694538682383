.atom__atom___9GaqW {
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  color: #444;
  padding: 1rem;
}

.atom__answer___2wNuF {
  margin: 0.5rem 0;
}

.atom__userContent___3JarV h1 {
  margin: 0;
  font-size: 1.5rem;
}

.atom__userContent___3JarV h2 {
  margin: 0;
  font-size: 1rem;
}
.atom__userContent___3JarV p {
  margin: 0;
}
.atom__userContent___3JarV > :first-child {
  padding-top: 0;
}
.atom__userContent___3JarV > :last-child {
  padding-bottom: 0;
}
.atom__userContent___3JarV h1 + p,
.atom__userContent___3JarV h1 + div,
.atom__userContent___3JarV h2 + p,
.atom__userContent___3JarV h2 + div,
.atom__userContent___3JarV p + p,
.atom__userContent___3JarV p + div,
.atom__userContent___3JarV div + p {
  margin: 0;
  padding: 0.625rem 0 0;
}

.atom__userContent___3JarV:not(.atom__userContentMc___1XI0r) {
  overflow-x: hidden;
  padding: 10px 5px;
}

.atom__userContent___3JarV h1 {
  font-size: 20px;
  padding: 0;
}

.atom__userContent___3JarV .atom__userContent___3JarV h2 {
  margin: 0 0 10px;
  font-size: 17px;
  padding: 0;
}

.atom__userContent___3JarV p {
  margin: 0 0 10px;
  padding: 0;
}

.atom__userContent___3JarV p:last-child {
  margin: 0;
  padding: 0;
}
.atom__userContent___3JarV p + table[data-source='cms-table'],
.atom__userContent___3JarV table[data-source='cms-table'] + p {
  padding: 1rem 0 0;
}

.atom__userContentMc___1XI0r table[data-source='cms-table'] {
  margin: 0;
}

.atom__question___1oH90.atom__userContent___3JarV table[data-source='cms-table'] {
  margin-top: 0;
  margin-bottom: 0;
}

.atom__atom___9GaqW .inline-math {
  position: relative;
  display: inline-block;
}

/* without a min-width, Desmos Everywhere graphs don't show up in multiple choice answer choices */
.atom__atom___9GaqW div[data-type='desmos'] {
  min-width: 400px;
}

.atom__atom___9GaqW .special-formula {
  text-align: center;
  margin: 5px 0;
}

.atom__general-explanation-wrapper___3S1On {
  margin-top: 1.5rem;
  padding: 0.625rem;
  background-color: #FFFFFF;
}
.atom__general-explanation-wrapper___3S1On h5 {
  margin: 0 0 0.5rem;
}

.atom__atomOptions___L249B {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
}

.atom__iconPrintDisabled___1gipx {
  height: 20px;
  width: 20px;
  margin-top: 0.5rem;
  margin-right: 1rem;
  cursor: pointer;
}
