.text-separator__container___1cVEb {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 24px;
  padding-bottom: 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}

.text-separator__line___2PnND,
.text-separator__longLine___1IzXU {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 1px;
  border-top: 1px solid #DDDDDD;
}

.text-separator__line___2PnND {
  width: 50%;
}

.text-separator__longLine___1IzXU {
  width: 100%;
}

.text-separator__text___1xNEt {
  font-size: 12px;
  color: #484848;
  padding: 0 8px;
}
