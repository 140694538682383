.settings-modal__header___239K0 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2.5rem;
}

.settings-modal__label___Repua {
  margin-top: 4rem;
  margin-bottom: 2.5rem;
}

.settings-modal__checkboxPadding___1Ornj {
  margin: 1rem !important;
}

.settings-modal__noPadding___2gExn {
  padding: 0rem;
}

.settings-modal__fieldCheckbox___1dkx8 {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}

.settings-modal__dropdownContainer___1JAtu {
  max-width: 22rem;
}

.settings-modal__dropdownContainerSmall___1z53u {
  width: 7.375rem;
  max-width: 11rem;
}

.settings-modal__dropdown___21Ocg {
  height: 2.5rem;
}

.settings-modal__warning___RCbcS {
  color: #C15100 !important;
}

.settings-modal__message___2Zmlo {
  color: white !important;
}

.settings-modal__resetLink___O1rTg {
  color: #008392 !important;
  cursor: pointer;
  font-weight: 600 !important;
  font-size: 0.875rem !important;
}

.settings-modal__supportBanner___1bEad {
  background-color: #E7F6F8;
  font-weight: 600;
  padding-left: 0.75rem;
  font-size: 0.75rem;
}

.settings-modal__supportLink___23pwS {
  color: #00A4B7;
  font-weight: 600;
  text-decoration: none;
}

.settings-modal__inputLabel___35sb7 {
  margin: 0;
  font-weight: 600;
  font-size: 0.75rem;
}
