.two-row-label-count__label___aY1Qr {
  letter-spacing: 0.06rem;
  color: #727272;
}

.two-row-label-count__warningIcon___3qiR7 {
  vertical-align: middle;
  margin: 0 0.5rem;
  fill: #C15100;
}
