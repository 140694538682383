.regrade-snackbar__snackbar___3d8Hi {
  background-color: #3b3b3b;
  padding: 0.5rem 1rem;
  width: 100%;
  max-width: 17.5rem;
  left: 0;
  right: auto;
  margin: 0 0.5rem;
  border-radius: 0.25rem;
  z-index: 2000;
}

.regrade-snackbar__active___1SVeH {
  -webkit-transform: translateY(-0.5rem) !important;
          transform: translateY(-0.5rem) !important;
}

.regrade-snackbar__button___1GiTy {
  margin: 0;
  padding: 0rem 0.5rem;
  color: #00A4B7 !important;
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1em;
  display: inherit;
}

.regrade-snackbar__button___1GiTy:hover {
  background-color: #3b3b3b !important;
}

.regrade-snackbar__label___29BCQ {
  color: #FFFFFF;
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  float: left;
  height: 2.25rem;
  line-height: 2.25rem;
}

.regrade-snackbar__progressText___jialG {
  color: #ABABAB !important;
  float: right;
  text-align: right;
}

@media screen and (max-width: 16.5rem) {
  .regrade-snackbar__snackbar___3d8Hi {
    border-radius: 0;
  }
}
