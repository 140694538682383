.assessment-questions__container___2OP3O:not(:first-child) {
  margin-top: 2.5rem;
}

.assessment-questions__emptyObjective___30Kwd {
  min-height: 12.5rem;
}

.assessment-questions__customQuestionsDivider___1dAbA {
  margin: 2.5rem 0;
}

.assessment-questions__message___2Krv5 {
  color: #C15100;
  font-size: 0.875rem;
  margin-left: 3rem;
  margin-top: 0.25rem;
}
.assessment-questions__button___3MGGN {
  margin-right: 0.875rem;
}

.assessment-questions__header___3IvYm {
  color: #C15100;
  font-size: 1rem;
}

.assessment-questions__icon___3BojV {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.75rem;
  margin-left: 1rem;
  fill: #C15100;
}
