.notice-box__icons___zF3-K {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1rem;
  fill: white;
}

.notice-box__messageBox___3k2xr {
  background-color: #727272;
  height: 2.12rem;
}
