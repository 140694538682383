.modal-footer__modalFooter___2CUaQ {
  max-width: 794px;
  width: 100%;
  padding: 1rem 0.5rem;
  margin: 0 auto;
}

.modal-footer__sticky___2ZtOi {
  max-width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  -webkit-box-shadow: 0px -4px 8px rgba(0, 0, 0, .16);
          box-shadow: 0px -4px 8px rgba(0, 0, 0, .16);
  z-index: 2000;
  background: white;
  transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1),
    transform 0.35s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-delay: 0.07s;
          transition-delay: 0.07s;
  opacity: 1;
}
