.paragraph__default___3yllm {
  color: #484848;
  padding: 0px !important;
}

.paragraph__context-background-light___2CVdY {
  color: #484848;
}

.paragraph__context-background-dark___3c9Tl {
  color: #FFFFFF;
}

.paragraph__align-start___2xh9M {
  text-align: start !important;
}

.paragraph__align-center___14WC3 {
  text-align: center !important;
}

.paragraph__align-end___zBkFA {
  text-align: end !important;
}

.paragraph__align-inherit___3XhI- {
  text-align: inherit;
}

.paragraph__margin-none___fBjn_ {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.paragraph__margin-small___X8NTJ {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.paragraph__margin-medium___23dnu {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.paragraph__margin-large___327Kp {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.paragraph__size-small___1JqED {
  font-size: 0.75rem !important;
  line-height: 1.125rem !important;
}

.paragraph__size-medium___38fgi {
  font-size: 1rem !important;
}

.paragraph__size-large___2-Yf1 {
  font-size: 1.5rem !important;
}

.paragraph__weight-regular___2mVtg {
  font-weight: 400 !important;
}

.paragraph__weight-semibold___38a2G {
  font-weight: 600 !important;
}

.paragraph__uppercase-true___lgiyX {
  text-transform: uppercase !important;
}

.paragraph__type-regular___3PG9q {
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.paragraph__type-monospace___24rts {
  font-family: "Monaco", "Consolas", "Lucida Console", monospace !important;
}
