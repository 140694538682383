.card-link__icon___2DF1f {
  width: 1.5rem;
  height: auto;
  max-height: 5.375rem;
  fill: #484848;
}

.card-link__cardMobile___3pmJv {
  margin: 1rem 0;
}

.card-link__cardDesktop___2yAzr {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  padding: 1.5rem;
  margin: 0 0.5rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.card-link__buttonWrapper___TL1fQ {
  font: inherit;
  text-align: left;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 1rem;
  border: 0;
}

.card-link__heading___32R2b {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.card-link__copy___yIDAE {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.card-link__copy___yIDAE .card-link__description___2ctEs {
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  padding-bottom: 0;
}

.card-link__cta___3diQf {
  font-size: 0.875rem;
}

@media (min-width: 768px) {
  .card-link__cardLinkGroup___ulu8x {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

  .card-link__cardMobile___3pmJv {
    display: none;
  }

  .card-link__cardDesktop___2yAzr {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .card-link__icon___2DF1f {
    width: 6rem;
  }

  .card-link__heading___32R2b {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .card-link__copy___yIDAE .card-link__description___2ctEs {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 2rem;
  }
}
