.card__card___3beLn {
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #DDDDDD;
}

.card__cardText___2o5Xh {
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
}

.card__cardText___2o5Xh p {
  font-size: 16px;
  line-height: 24px;
  color: #484848;
}

.card__cardTitle___5N_SH {
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
}

.card__cardTitle___5N_SH.card__large___1ouSh .card__title___1yHgX {
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: #484848;
}

.card__cardTitle___5N_SH.card__large___1ouSh .card__subtitle___14mq6 {
  font-size: 16px;
  line-height: 24px;
  color: #727272;
  font-weight: 400;
}

.card__cardTitle___5N_SH.card__small___3Q9b3 .card__title___1yHgX {
  font-size: 14px;
  line-height: 28px;
  font-weight: 600;
  color: #484848;
}

.card__cardTitle___5N_SH.card__small___3Q9b3 .card__subtitle___14mq6 {
  font-size: 12px;
  line-height: 28px;
  font-weight: 400;
  color: #484848;
}
