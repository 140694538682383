.forgot-password__logo___LX-pC {
  position: absolute;
  left: 16px;
  top: 24px;
}

.forgot-password__container___30LW7 {
  margin: 0 auto;
  width: 100%;
  max-width: 460px;
  vertical-align: middle;
  padding: 104px 16px 40px 16px;
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
}

.forgot-password__cardTitle___277EC {
  padding: 0 0 24px 0 !important;
}

.forgot-password__cardTitleHeader___3Gey1 {
  font-size: 34px !important;
  line-height: 40px !important;
  font-weight: 400 !important;
}

.forgot-password__input___3I-xK,
.forgot-password__emailInput___3gG3I {
  padding-bottom: 32px;
}

.forgot-password__text___2DlRZ {
  font-size: 16px;
  margin: 0 0 16px 0;
}

.forgot-password__card___2rnHz {
  max-width: 460px;
  padding: 16px;
}

.forgot-password__button___3Lt4H {
  margin-top: 16px;
  width: 100%;
}

.forgot-password__confirmationScreen___2LDpm {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.forgot-password__confirmationScreen___2LDpm > p {
  text-align: center;
}

.forgot-password__emailIcon___s8FCC {
  height: 94px;
  width: 86px;
}

.forgot-password__circle___1a3lY {
  background-color: #DDDDDD;
}

.forgot-password__signinLink___1-2M2 {
  position: absolute;
  top: 24px;
  right: 16px;
  font-weight: 600;

  color: #FFFFFF;
  font-size: 14px;
  text-decoration: none;
}

.forgot-password__signinLink___1-2M2:hover {
  color: #DDDDDD !important;
}

.forgot-password__signinLink___1-2M2:active {
  color: #CCCCCC !important;
}

.forgot-password__signinLink___1-2M2:visited {
  color: #FFFFFF;
}

.forgot-password__textLink___2xChU {

  display: inline-block;
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  margin-left: 0.5rem;
  font-size: 16px;
  text-decoration: none;
  color: #008392;
}

.forgot-password__textLink___2xChU:active {
  color: #21243C;
}

.forgot-password__emailHeader___3avq7 {
  font-size: 34px;
  line-height: 40px;
  padding: 0 0 24px 0;
  margin: 8px 0 0 0;
  color: #484848;
}

.forgot-password__sendEmailButton___2CnD4 {
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  background: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #008392;
  border: none;
  font-size: 16px;
}

.forgot-password__sendEmailButton___2CnD4:hover {
  color: #45BCCA;
  cursor: pointer;
}

.forgot-password__signInCorner___33nNC {
  color: #FFFFFF;
  position: absolute;
  right: 1rem;
  top: 0.9rem;
  cursor: pointer;
  font-weight: 600;
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  letter-spacing: 0.1rem;
}

@media screen and (min-width: 480px) {
  .forgot-password__container___30LW7 {
    padding: 144px 0 80px 0;
  }

  .forgot-password__card___2rnHz {
    padding: 40px;
  }
}
