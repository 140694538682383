.alert-box__warningBox___2axu7 {
  border: 0.0625rem solid;
  border-color: #C15100;
  border-radius: 0.25rem;
  background-color: #FFF9F2;
}

.alert-box__header___15iHC {
  color: #C15100;
  font-size: 1rem;
}

.alert-box__icon___3_-Ys {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.75rem;
  margin-left: 1rem;
  fill: #C15100;
}

.alert-box__button___2BzJM {
  margin-right: 0.875rem;
  color: #C15100;
  border-color: #C15100;
}

.alert-box__button___2BzJM:hover {
  border-color: #C15100;
}
