.assessment-setting-reset-warning__bannerContainer___2jkFX {
  background-color: #FEFAF0;
  -webkit-box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, .24), 0 0 0.125rem rgba(0, 0, 0, .12);
          box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, .24), 0 0 0.125rem rgba(0, 0, 0, .12);
  border-radius: 0.25rem;
}

.assessment-setting-reset-warning__bannerIcon___1nClh {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.75rem;
  margin-left: 1rem;
  fill: #9c823b;
}

.assessment-setting-reset-warning__bannerMessage___3pOTY {
  font-size: 0.875rem !important;
  color: #9c823b !important;
}

.assessment-setting-reset-warning__bannerResetText___1tggV {
  font-size: 0.875rem !important;
  font-weight: 600;
  color: #008392 !important;
  cursor: pointer;
  border: none;
  text-transform: none;
}
