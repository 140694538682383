.edit-question-points__editQuestionPoints___2totc {
  position: absolute;
  width: 22.5rem;
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, .24), 0px 0px 2px rgba(0, 0, 0, .12);
          box-shadow: 0px 2px 8px rgba(0, 0, 0, .24), 0px 0px 2px rgba(0, 0, 0, .12);
  padding: 0 1rem 1rem 1rem;
  z-index: 2000;
  text-transform: none;
  text-transform: initial;
}

.edit-question-points__setPoints___2Cfo4 {
  color: #484848;
}

.edit-question-points__hidden___14ELe {
  display: none;
}

.edit-question-points__errorIcon___3ox_8 {
  fill: #C84E50;
  margin-right: 0.5rem;
}

.edit-question-points__errorMsg___2Em2Q {
  color: #C84E50;
}

.edit-question-points__loadingIcon___2MZZJ {
  fill: #ABABAB;
}

.edit-question-points__saveState___Hk2vW {
  font-size: 0.75rem;
  font-weight: 400;
}

.edit-question-points__editQuestionsInput___1_cVv {
  width: 100%;
  padding: 1.5rem 0;
}

.edit-question-points__editQuestionsInputErrored___18grg {
  padding-bottom: 0;
}

.edit-question-points__editQuestionsInputElement___218RY {
  height: 3.5rem;
  background: #F8F8F8;
  padding-left: 0.75rem;
  padding-right: 5rem;
}

.edit-question-points__validationError___1PiFV {
  size: 0.75rem;
  font-weight: 400;
}

.edit-question-points__inputWeight___2ij9F {
  position: absolute;
  right: 0;
  margin-right: 2rem;
  color: #727272;
  font-size: 1rem;
  font-weight: 400;
}

.edit-question-points__poolQuestion___31Be9 {
  margin-bottom: none !important;
}
