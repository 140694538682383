.dropdown__group___y9pjq {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

.dropdown__label___1yaPA {
  color: #727272;
  font-size: 0.75rem;
  line-height: 1rem;
}

.dropdown__dropdownMenu___2UFAV {
  border: none;
  font-size: 16px;
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  border-bottom: 1px solid #727272;
  border-radius: 0 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2.5em;
  padding: 8px;
  padding-left: 0;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%23000000' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-color: transparent;
  background-position: 100% 50%;
  background-repeat: no-repeat;
}

.dropdown__dropdownMenu___2UFAV[disabled] {
  color: #000000;
  opacity: 0.5;
}

.dropdown__description___1swUL {
  font-size: 0.75rem;
  line-height: 1.25rem;
  margin-top: 0.25rem;
  color: #585858;
}
