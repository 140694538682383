.material-layout__layoutBackToTop___3RAsr {
  background: #21243C;
  text-align: center;
  color: #FFFFFF;
  font-size: 0.75rem;
  text-decoration: none;
  text-transform: uppercase;
}

.material-layout__layoutBackToTop___3RAsr:focus {
  padding: 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: auto;
  -webkit-clip-path: none;
          clip-path: none;
  clip: auto;
  clip: initial;
}

.material-layout__mainContentLinkContainer___27Bbq {
  background: #21243C;
  text-align: center;
}

.signed-out-page .material-layout__mainContentLinkContainer___27Bbq {
  background: transparent;
}

.material-layout__mainContentLink___3Aeba {
  color: #FFFFFF;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  font-size: 12px;
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
          clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  outline: 0.125rem solid #FFFFFF;
}

.material-layout__mainContentLink___3Aeba:focus {
  padding: 1rem;
}

.material-layout__linkVisible___IQS-a {
  position: relative !important;
  margin: 8px 0;
  height: auto;
  width: auto;
  overflow: visible;
  -webkit-clip-path: none;
          clip-path: none;
}

@-moz-document url-prefix() {
  .material-layout__printableMode___3KSXc {
    display: block;
  }
}
