.alchemie-vsper-atom__learnosity___2LD9K {
    color: #444444;
}

/*
 Scale desmos (and answers) with smaller screen widths
 */
@media screen and (max-width: 550px) {
    .alchemie-vsper-atom__learnosity___2LD9K .calculator {
        -webkit-transform-origin: left top;
                transform-origin: left top;
        /* 0.7 * 1.43 = 1 */
        max-width: 143%;
        -webkit-transform: scale(0.7);
                transform: scale(0.7);
    }
}

@media screen and (max-width: 550px) {
    .alchemie-vsper-atom__learnosity___2LD9K .desmos_learnosity_item .lrn_correctAnswers {
        -webkit-transform-origin: left top;
                transform-origin: left top;
        /* 0.7 * 1.43 = 1 */
        max-width: 143%;
        -webkit-transform: scale(0.7);
                transform: scale(0.7);
    }
}

/*
 Desmos will attempt to scale up for some minimum
 size with inline styles. Rather than fight it,
 just force its origin to match our transformation.
 */
.alchemie-vsper-atom__learnosity___2LD9K .dcg-wrapper {
    -webkit-transform-origin: left top;
            transform-origin: left top;
}

.alchemie-vsper-atom__error___1e6rE {
    color: #C84E50;
}

.alchemie-vsper-atom__learnosity___2LD9K .question.user-content {
    margin-bottom: 35px;
}

.alchemie-vsper-atom__learnosity___2LD9K .lrn_toolbar {
    background-color: #f7f7f7;
    min-height: 0em;
}

.alchemie-vsper-atom__learnosity___2LD9K .lrn {
    color: #444444;
    font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
}

.alchemie-vsper-atom__learnosity___2LD9K .lrn .lrn_btn {
    background-color: #f7f7f7;
    color: #444444;
}

.alchemie-vsper-atom__learnosity___2LD9K .lrn_btn.lrn_tool_button.lrn_active {
    background-color: #00abc7;
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #FFFFFF;
}

.alchemie-vsper-atom__learnosity___2LD9K .lrn_btn.lrn_tool_button {
    background-color: #f7f7f7;
    color: #444444;
}

.alchemie-vsper-atom__learnosity___2LD9K .lrn .lrn_btn[disabled] {
    background-color: #f7f7f7;
}

.alchemie-vsper-atom__learnosity___2LD9K .lrn_widget .lrn_response_input_wrapper.lrn_correct {
    margin-top: 0;
}

.alchemie-vsper-atom__learnosity___2LD9K .lrn_widget .lrn_response_input_wrapper.lrn_incorrect {
    margin-top: 0;
}

.alchemie-vsper-atom__learnosity___2LD9K .lrn_widget.lrn_graphplotting .lrn_response_wrapper .lrn_graph_plotter {
    background-color: white;
}

.user-history-response .lrn .lrn_clearfix {
    zoom: 0.7;
}

.alchemie-vsper-atom__learnosity___2LD9K .hide_response_input .lrn_response_input_wrapper {
    display: none;
}

.alchemie-vsper-atom__learnosity___2LD9K .lrn_imagecloze_response .mq-empty {
    display: none;
}

.alchemie-vsper-atom__learnosity___2LD9K .lrn_imageclozechemistry .lrn_correctAnswers > span:first-child {
    display: none;
}

.alchemie-vsper-atom__learnosity___2LD9K .lrn_response_input.lrn_bordered_mathinput.lrn_response_math {
    overflow: auto !important;
}

.learn-flow-body .lrn_mcqgroup > li {
    font-family: --"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 16px;
    border-bottom: 1px solid #DDDDDD;
    min-height: 56px;
    height: auto;
    width: 100%;
    padding: 16px 0;
    border-left-style: none !important;
    -webkit-transition-duration: 0s !important;
            transition-duration: 0s !important;
}

.learn-flow-body .lrn_mcqgroup .lrn_contentWrapper {
    margin-top: 8px;
    padding-left: 10px;
}

.learn-flow-body .lrn_mcqgroup {
    border-left-style: none !important;
    border-bottom-style: none !important;
    border-right-style: none !important;
    border-top-style: none !important;
}

.learn-flow-body .lrn_mcqgroup > li:hover {
    background-color: #F8F8F8 !important;
    cursor: pointer;
}

.learn-flow-body .lrn_selected {
    background-color: white !important;
}

.learn-flow-body .lrn_mcqgroup.lrn_correct::after {
    display: none !important;
}

.learn-flow-body .lrn_mcqgroup.lrn_incorrect::after {
    display: none !important;
}

/************************************************
* Math Keypad dropdown custom styles
*************************************************/

/* Add a keyboard icon next to the dropdown selection */
.alchemie-vsper-atom__learnosity___2LD9K
.lrn-formula-keyboard-menu
.lrn_dropdown.lrn_theme_primary
.lrn_dropdown_toggle {
    /* For some unknown reason, if this is an absolute url (app/..) it fails to find the file. */
    background: url(/jsx/img/icon-keyboard.4d38d2ea62c9ba99266c.svg) 2px 2px no-repeat;
    padding-left: 30px;
}

.alchemie-vsper-atom__learnosity___2LD9K
.lrn-formula-keyboard-menu
.lrn_dropdown.lrn_theme_primary
.lrn_dropdown_toggle:not(:hover) {
    color: #444;
}

/* Replace keypad dropdown selection by title */
.alchemie-vsper-atom__learnosity___2LD9K button.lrn_btn.lrn_dropdown_toggle:after {
    content: attr(title);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 130px;
    display: block;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
}

/* Hide keypad dropdown selection icon */
.alchemie-vsper-atom__learnosity___2LD9K .lrn_dropdown.lrn_theme_primary .lrn_dropdown_toggle .lrn_content {
    display: none;
}

/* Make the input a bit biggie to fit the text */
.alchemie-vsper-atom__learnosity___2LD9K .lrn_widget .lrn-formula-keyboard-menu-left .lrn_dropdown {
    width: 180px;
}

/* Replace keypad dropdown icons by title */
.alchemie-vsper-atom__learnosity___2LD9K button.lrn_btn.lrn_dropdown_option:after {
    content: attr(title);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 130px;
    display: block;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
}

/* Hide keypad dropdown icons */
.alchemie-vsper-atom__learnosity___2LD9K button.lrn_btn.lrn_dropdown_option .lrn_content {
    visibility: hidden;
    position: absolute;
}

/* Hides ghost learnosity elements in the page */
.alchemie-vsper-atom__ghost___dMcfh {
    position: absolute;
    display: none;
}
/* Authoring component styles */

.alchemie-vsper-atom__learnosityAuthWrapper___2Ut16 {
    padding: 40px;
}

.alchemie-vsper-atom__learnosityAuthFooter___3IPAK {
    border-top: #CCCCCC solid 1px;
    height: 60px;
    line-height: 60px;
    text-align: right;
    padding-right: 40px;
}

.alchemie-vsper-atom__saveCustomItemButton___2-SKJ {
    margin-left: 1rem;
}

.alchemie-vsper-atom__learnosity___2LD9K .lrn-qe-tab-sub-content {
    display: none;
}

.lrn-formula-keyboard-menu-response {
    color: #000000;
}

button.lrn-qe-more-options {
    color: #000000 !important;
    border: none !important;
}

/* This hides the "Example Correct Answer" text above the correct answer */
.desmos_learnosity_item .lrn_correctAnswers > span:first-child {
    display: none;
}

.alchemie-vsper-atom__helpIcon___1xwn7 {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    fill: #ABABAB !important;
    float: right;
}

.alchemie-vsper-atom__helpTooltip___3ggzN {
    float: right;
}

/* remove red or green border around graded learnosity questions */
.learn-flow-body .lrn-response-validate-wrapper {
    border-left-style: none !important;
    border-bottom-style: none !important;
    border-right-style: none !important;
    border-top-style: none !important;
}

/* remove red border around graded learnosity questions in sequence view */
.lrn_response_input_wrapper.lrn_incorrect {
    border-left-style: none !important;
    border-bottom-style: none !important;
    border-right-style: none !important;
    border-top-style: none !important;
}

/* remove green border around graded learnosity questions in sequence view */
.lrn_response_input_wrapper.lrn_correct {
    border-left-style: none !important;
    border-bottom-style: none !important;
    border-right-style: none !important;
    border-top-style: none !important;
}

/* remove checkmark or cross on graded learnosity questions */
.lrn_correct:not(.lrn_textinput)::after {
    display: none !important;
}

.lrn_incorrect:not(.lrn_textinput)::after {
    display: none !important;
}

/* center answers vertically */
.lrn_correctAnswerList li {
    vertical-align: middle !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

/* style cloze answers */
.lrn_cloze .lrn_correctAnswerList li {
    border: 1px solid #666;
}

/* remove radius styles on cloze index */
.lrn_responseIndex {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

/* remove weird underscore in cloze types */
.lrn-clozeformula-input.lrn_not_attempted .lrn_bordered_mathinput {
    border: none !important;
}

/* prevent top of graph from being cut off */
.lrn_graphplotting .lrn_response_input_wrapper {
    margin-top: 0 !important;
}

/* standardize border around cloze input */
span.lrn-clozeformula-input.lrn_disabled.lrn_not_attempted {
    border: 1px solid #888;
    border-top-color: rgb(136, 136, 136);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(136, 136, 136);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(136, 136, 136);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-width: 0px;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

/* set border properties for cloze inputs in content browse */
.assignment-browse-content .lrn-clozeformula-input {
    border: solid !important;
    border-width: 1px !important;
    border-color: #888 !important;
    border-radius: 2px !important;
}

/* Customizations to ckeditor */

/* ckeditor backdrop - translucent gray background underneath the widget
Note: Learnosity tries to give this a low z-index using !important as well,
so we make ours slightly more specific with div, so it shows above the modal.
*/
div.cke_dialog_background_cover {
    z-index: 3000 !important;
}

/* ckeditor dialog (we use important to override Learnosity's inline CSS) */
.cke_dialog_container {
    z-index: 3000 !important;
}

/* ckeditor text color */
.cke_button.cke_button__textcolor.cke_button_off {
    display: none;
}

/* ckeditor underline */
.cke_button.cke_button__underline.cke_button_off {
    display: none;
}

/* ckeditor text color divider */
.cke_button__textcolor + span {
    display: none;
}

/* ckeditor selector for mathml */
.lrn-qe-ck-math-type.cke_dialog_ui_input_select {
    display: none;
}

/* ckeditor source button */
.cke_toolbar:nth-child(8) {
    display: none;
}

/* ckeditor margin left */
.teach-page .cke_button.cke_button__indent.cke_button_off {
    display: none;
}

/* ckeditor margin right */
.cke_button.cke_button__outdent.cke_button_disabled {
    display: none;
}

/* ckeditor margin right divider */
.cke_button.cke_button__bulletedlist.cke_button_off + span {
    display: none;
}

/* ckeditor table */
.cke_button.cke_button__table.cke_button_off {
    display: none;
}

/* ckeditor quote */
.cke_button.cke_button__blockquote.cke_button_off {
    display: none;
}

/* ckeditor simple feature (video, audio, ruler, protractor) */
.cke_button.cke_button__lrnsimplefeature.cke_button_off {
    display: none;
}

/* ckeditor text styles button */
.cke_toolbar:nth-child(7) {
    display: none;
}

/* use the standard link color for contrast and clarity  */
.alchemie-vsper-atom__link___-_i2D {
    color: #0000EE;
}

.alchemie-vsper-atom__link___-_i2D:hover {
    cursor: pointer;
}
