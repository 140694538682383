.dataset-atom__datasetAtom___xuCKN {
  text-align: center;
}

.dataset-atom__datasetAtom___xuCKN button:hover {
  background-color: transparent !important;
  color: #484848 !important;
}

.dataset-atom__datasetWrapper___3s2A_ {
  margin: 0 auto;
}

.dataset-atom__error___3Sgvr {
  color: #C84E50;
}

/* use the standard link color for contrast and clarity  */
.dataset-atom__link___3U08U {
  color: #0000EE;
}

.dataset-atom__link___3U08U:hover {
  cursor: pointer;
}

.react-grid-Main {
  clear: none;
  margin-right: -0.125rem; /* to display the custom scrollbar fully */
}

.react-grid-Container {
  clear: none;
}

.react-grid-Canvas {
  overflow: auto !important;
}

/* custom scrollbar */
.react-grid-Canvas::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.react-grid-Canvas::-webkit-scrollbar-thumb {
  background: #ABABAB;
  border-radius: 0.625rem;
}

.react-grid-Canvas::-webkit-scrollbar-thumb:hover {
  background: #585858;
}

.react-grid-HeaderCell {
  background-color: #FFFFFF;
}

.dataset-atom__datasetAtomHeaderColumn___u4guJ {
  font-weight: 600;
}

.dataset-atom__copyButton___h1ac2 {
  font-size: 12px;
  font-weight: 600;
  padding: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.dataset-atom__downloadButton___3KcVn {
  margin-left: 8px;
}

.dataset-atom__helpButton___2VI9R {
  margin-right: 8px;
}

.dataset-atom__datasetButton___qIuC5:hover:not(:disabled):not(.dataset-atom__disabled___HF-TN) {
  background-color: #F8F8F8;
  color: #727272;
}

.dataset-atom__iconDatasetButton___2b3aJ {
  fill: #666;
  height: 20px;
  width: 20px;
  margin-right: 8px;
  margin-bottom: -5px;
}
