.external-atom__atomExternalContainer___12poL {
  margin-left: auto;
  margin-right: auto;
  text-align: right;
}

.external-atom__atomExternal___1rKQt {
  overflow: hidden;
  /*Assuming the aspect ration of the content is 16:9 (9/16= 0.5625)*/
  padding-top: 56.25%;
  position: relative;
  max-width: 100%;
}

.external-atom__atomExternalCustomDimension___UQ3UG {
  position: relative;
  max-width: 100%;
}

.external-atom__atomExternalContainer___12poL iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.external-atom__error___1AhQe {
  color: #C84E50;
}
