.textinput__textInput___3LRRb {
  color: #000000;
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
}

.textinput__bar____fWlY::before {
  background-color: #008392;
}

.textinput__bar____fWlY::after {
  background-color: #008392;
}

.textinput__inputElement___3kJCA {
  margin-bottom: 0rem;
}

.textinput__inputElement___3kJCA:focus {
  border: none;
}

.textinput__counter___1u4Dy,
.textinput__error___GJOsq,
.textinput__description___RfMzD {
  margin-top: 0.25rem;
}

.textinput__errored___2SW_4 .textinput__description___RfMzD {
  margin-top: 0;
}

.textinput__error___GJOsq {
  color: #C84E50;
}

/* Styles for input with icon */
.textinput__withIcon___2labQ {
  margin-left: 0 !important;
}

.textinput__withIcon___2labQ > .textinput__inputElement___3kJCA {
  padding-left: 3rem;
}

.textinput__withIcon___2labQ > .textinput__icon___3aFfb {
  left: 0;
}

.textinput__withIcon___2labQ > label {
  margin-left: 3rem;
}

/* This is the selector used for a workaround as opposed to applying it only to a label class */

.textinput__textInput___3LRRb > .textinput__inputElement___3kJCA:focus:not([disabled]):not([readonly]) ~ label:not(.textinput__fixed___2XZjl) {
  color: #008392;
}

.textinput__counter___1u4Dy,
.textinput__textInput___3LRRb > label,
.textinput__description___RfMzD {
  color: #585858;
}

.textinput__textInput___3LRRb > .textinput__inputElement___3kJCA {
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  border-bottom-color: #727272;
  color: #484848;
  resize: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.textinput__textInput___3LRRb > input {
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  color: #484848;
}

.textinput__hint___3Vv5j {
  color: #585858;
}

/* This is the real label class that we can use to theme the component when react toolbox gets their ish together with the interface */

/*.textInput>.inputElement:focus:not([disabled]):not([readonly])~.label:not(.fixed) {
    color: var(--blue-500);
}*/
