/* react-select Overriden styles */
.select__reactSelectContainer___1D39E {
  width: 100%;
}

.select__reactSelectContainer___1D39E .react-select__control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-color: #727272;
}

.select__reactSelectContainer___1D39E .react-select__control--is-focused {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #727272;
}

.select__reactSelectContainer___1D39E .react-select__control:hover {
  border-color: #727272;
}

.select__reactSelectContainer___1D39E .react-select__value-container {
  padding-left: 0;
  color: #484848;
}

.select__reactSelectContainer___1D39E .react-select__multi-value {
  color: #484848;
  padding: 0.25rem;
  background-color: #DDDDDD;
}

.select__reactSelectContainer___1D39E .react-select__multi-value__remove {
  margin-left: 0.25rem;
}

.select__reactSelectContainer___1D39E .react-select__indicator-separator {
  display: none;
}

.select__reactSelectContainer___1D39E .react-select__option {
  cursor: pointer;
  color: #585858;
}

.select__reactSelectContainer___1D39E .react-select__option--is-selected {
  background-color: #b2d4ff;
}

.select__reactSelectContainer___1D39E .react-select__option--is-selected:active {
  background-color: #b2d4ff;
}

.select__reactSelectContainer___1D39E .react-select__option--is-selected:hover {
  background-color: #b2d4ff;
}
