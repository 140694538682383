.video-atom__transcript___3zGp1 {
  max-height: 320px;
  overflow: scroll;
  border: 1px solid #CCCCCC;
  padding: 15px;
  margin-top: 5px;
}

.video-atom__transcriptButton___1upcF {
  cursor: pointer;
  background-color: transparent;
  border: none;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: black !important;
}

.video-atom__transcriptButton___1upcF:hover {
  background-color: transparent !important;
  background: transparent !important;
  color: black !important;
}

.video-atom__transcriptButton___1upcF svg {
  vertical-align: middle;
}
