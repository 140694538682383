.register__container___3zuIT {
  margin: 0 auto;
  width: 100%;
  padding: 9rem 1rem 5rem 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
}

.register__heroImg___3aGKS {
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
}

.register__registrationCard___3oMQb {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 28.75rem;
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 200;
  padding: 1rem;
}

.register__primaryRoleGroup___2G8Iw {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

.register__primaryRoleGroup___2G8Iw > label {
  margin-right: 1.875rem;
}

.register__primaryRoleGroup___2G8Iw > label > span {
  font-weight: 400;
}

.register__signInText___1Nl-g {
  margin: 0;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  text-align: center;
}

.register__googleRegisterBtn___3z1BC {
  margin-top: 0.625rem;
  margin-left: auto;
  margin-right: auto;
}

.register__registerText___HoxXf {
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #727272;
  font-weight: normal;
  text-align: center;
}

.register__signInTextLink___3qavG {
  line-height: 1.5rem;
}

.register__signInTextLink___3qavG,
.register__registerTextLink___2Kedy {
  text-decoration: none;
  color: #008392;
}

.register__distributorSignInTextLink___UhN21,
.register__distributorRegisterTextLink___1XHSx {
  font-weight: 600;
}

.register__signInTextLink___3qavG:active,
.register__registerTextLink___2Kedy:active,
.register__distributorRegisterTextLink___1XHSx:active {
  color: #004b54;
}

.register__signInLink___1UX9J {
  position: absolute;
  right: 1rem;
  top: 1.5rem;
  text-decoration: none;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 0.875rem;
}

.register__registerTitle___Ikp0f {
  margin: 0 auto;
  padding: 0 0 1.5rem 0 !important;
  font-size: 2.125rem;
}

.register__marketingText___1Ge20 {
  height: 3.5rem;
  color: #FFFFFF;
  max-width: 28.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
}

.register__messagingWrapper___3pxcc {
  width: 100%;
}

.register__registerTitle___Ikp0f > div {
  margin: 0 auto;
}

.register__registerTitle___Ikp0f > div > h5 {
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 2.125rem !important;
  font-weight: normal !important;
  line-height: 2.5rem !important;
  text-align: center;
}

.register__registerBtn___XJeLK {
  margin-bottom: 1rem;
}

.register__createAccountBtn___3Fq51 {
  width: 100%;
}

.register__createAccountBtn___3Fq51:disabled {
  background-color: #ABABAB;
  color: #FFFFFF;
}

.register__birthday___3kgcV {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-column-gap: 0.125rem;
          column-gap: 0.125rem;
}

.register__dropdown___2N-Dx {
  height: 2.5rem;
}

.register__roleRadioBtn___3c33f {
  padding-left: 1rem;
}

.register__roleRadioInput___C7QBL:focus ~ .register__roleRadioBtnChecked___2Gt_M {
  -webkit-box-shadow: 0 0 0 0.625rem #8390d5 !important;
          box-shadow: 0 0 0 0.625rem #8390d5 !important;
}

.register__registerSeparator___IOMz2 {
  margin-top: 1rem;
}

.register__textInput___36KkZ {
  padding-bottom: 1rem;
  color: #727272 !important;
  border-bottom-color: #727272;
}

.register__textInput___36KkZ > span[role='alert'] {
  display: block;
  margin-top: 0.5rem;
  line-height: 1rem;
}

.register__textInput___36KkZ > label {
  color: #727272;
}

.register__passwordReqs___3_lrO {
  display: block;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #484848;
  margin-top: -0.5rem;
}

.register__dobLabel___lSIma {
  display: block;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: #727272;
  font-size: 1rem;
}

.register__togglePasswordGroup___Yl9pN {
  position: relative;
}

.register__togglePassword___1ILuP {
  position: absolute;
  right: 0;
  top: -webkit-calc(50% - 1em);
  top: calc(50% - 1em);
  z-index: 1;
  border: none;
  background-color: transparent;
}

.register__primaryRoleText___3ciff {
  font-size: 1rem;
  color: #727272;
  margin: 1rem 0;
}

.register__togglePasswordIcon___HdIQB {
  fill: #727272;
  height: 2.3em;
  width: 2.3em;
}

.register__togglePassword___1ILuP:hover {
  fill: #484848;
  cursor: pointer;
}

.register__togglePasswordActive___3QuEc {
  fill: #484848;
}

@media screen and (min-width: 30rem) {
  .register__container___3zuIT {
    padding-top: 6.5rem;
  }

  .register__registrationCard___3oMQb {
    padding: 2.5rem;
  }
}

.register__connectedText___28tu5 {
  margin-bottom: 1rem;
}
