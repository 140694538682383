.login__loginPage___3wRCF {
  background: -webkit-linear-gradient(134.78deg, #636bb5 0%, #008392 100.39%), #ffffff;
  background: linear-gradient(315.22deg, #636bb5 0%, #008392 100.39%), #ffffff;
}

.login__container___1KPAg {
  margin: 0 auto;
  width: 100%;
  max-width: 460px;
  vertical-align: middle;
  padding-top: 104px;
  padding-left: 16px;
  padding-right: 16px;
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 200;
}

.login__signInBtn___3f4fe {
  width: 100%;
  height: 2.6em;
  margin-bottom: 16px;
}

.login__signInBtn___3f4fe:disabled {
  background-color: #ABABAB;
  color: #FFFFFF;
}

.login__logo___1i_Kj {
  position: absolute;
  left: 16px;
  top: 24px;
}

[data-whatintent='mouse'] .login__logo___1i_Kj::after {
  display: none;
}

.login__inputField___fJA0e {
  margin: 8px 0;
  width: 100%;
}

.login__inputField___fJA0e > label {
  color: #484848;
  font-weight: 400 !important;
}

.login__inputField___fJA0e > input {
  color: #484848 !important;
}

/* Input error label */
.login__inputField___fJA0e > span[role='alert'] {
  display: block;
  margin-top: 8px;
}

.login__signInTitle___3tonO {
  padding-bottom: 24px !important;
}

.login__signInTitle___3tonO > div > h5 {
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 34px !important;
}

.login__rememberMe___3cG7W {
  font-weight: 400 !important;
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif !important;
  margin: 24px 0 !important;
  padding: 1rem 0 !important;
}

.login__rememberMe___3cG7W > span {
  color: #484848;
}

.login__rememberMeContainer___1OCNl {
  -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
          align-self: flex-start;
}

.login__signInTitle___3tonO {
  font-size: 34px;
}

.login__signInTitle___3tonO > div > h5 {
  color: #585858;
  font-weight: 400 !important;
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
}

.login__loginCard___3sUGI {
  max-width: 460px;
  padding: 16px;
}

.login__loginCard___3sUGI > form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.login__registerText___3cRm9 {
  margin: 0 auto;
  color: #008392;
}

.login__link___Kl1JL {
  line-height: 24px;
  color: #008392;
  text-decoration: none;
}

.login__link___Kl1JL:visited {
  color: #008392;
}

.login__link___Kl1JL:active {
  color: #004b54;
}

.login__togglePasswordGroup___2NhDg {
  width: 100%;
  position: relative;
}

.login__togglePassword___25eGV {
  position: absolute;
  right: 0;
  top: -webkit-calc(50% - 1em);
  top: calc(50% - 1em);
  z-index: 1;
  border: none;
  background-color: transparent;
}

.login__togglePasswordIcon___1e7e7 {
  fill: #727272;
  height: 2.3em;
  width: 2.3em;
}

.login__togglePassword___25eGV:hover {
  fill: #484848;
  cursor: pointer;
}

.login__togglePasswordActive___1bztk {
  fill: #484848;
}

@media screen and (min-width: 480px) {
  .login__container___1KPAg {
    padding: 144px 0 80px 0;
  }

  .login__loginCard___3sUGI {
    padding: 40px;
  }
}
