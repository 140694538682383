.heading__context-background-light___DGg-M {
  color: #484848;
}

.heading__context-background-dark___D_k0X {
  color: #FFFFFF;
}

.heading__align-start___YH8yb {
  text-align: start;
}

.heading__align-center___2V06v {
  text-align: center;
}

.heading__align-end___SCxUJ {
  text-align: end;
}

/** margin, padding **/

.heading__margin-none___2FcqE {
  margin: 0;
}

.heading__margin-horizontal-none___cBx0W {
  margin-left: 0;
  margin-right: 0;
}

.heading__margin-vertical-none___3Jtks {
  margin-top: 0;
  margin-bottom: 0;
}

.heading__margin-top-none___3AMPP {
  margin-top: 0;
}

.heading__margin-right-none___14Pss {
  margin-right: 0;
}

.heading__margin-bottom-none___stxXv {
  margin-bottom: 0;
}

.heading__margin-left-none___3f2sO {
  margin-left: 0;
}

.heading__margin-small___2Dpfn {
  margin: 0.5rem;
}

.heading__margin-horizontal-small___1A_FA {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.heading__margin-vertical-small___3kyqH {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.heading__margin-top-small___DL2Iy {
  margin-top: 0.5rem;
}

.heading__margin-right-small___1o2Dp {
  margin-right: 0.5rem;
}

.heading__margin-bottom-small___1Cc0L {
  margin-bottom: 0.5rem;
}

.heading__margin-left-small___10Fh2 {
  margin-left: 0.5rem;
}

.heading__margin-medium___KXPLA {
  margin: 1rem;
}

.heading__margin-horizontal-medium___36VKA {
  margin-left: 1rem;
  margin-right: 1rem;
}

.heading__margin-vertical-medium___11aNP {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.heading__margin-top-medium___1UERx {
  margin-top: 1rem;
}

.heading__margin-right-medium___2SNpj {
  margin-right: 1rem;
}

.heading__margin-bottom-medium___cmfhz {
  margin-bottom: 1rem;
}

.heading__margin-left-medium___1mgWp {
  margin-left: 1rem;
}

.heading__margin-large___3heKO {
  margin: 2rem;
}

.heading__margin-horizontal-large___m6jV8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.heading__margin-vertical-large___2BujA {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.heading__margin-top-large___2aDYD {
  margin-top: 2rem;
}

.heading__margin-right-large___2n28C {
  margin-right: 2rem;
}

.heading__margin-bottom-large___3leTw {
  margin-bottom: 2rem;
}

.heading__margin-left-large___WguX8 {
  margin-left: 2rem;
}

.heading__pad-none___3FRPr {
  padding: 0 !important;
}

.heading__pad-horizontal-none___kHlVk {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.heading__pad-vertical-none___35zng {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.heading__pad-top-none___1JclL {
  padding-top: 0 !important;
}

.heading__pad-right-none___GeoTP {
  padding-right: 0 !important;
}

.heading__pad-bottom-none___3Q2nc {
  padding-bottom: 0 !important;
}

.heading__pad-left-none___3j7qs {
  padding-left: 0 !important;
}

.heading__pad-small___3Dvi5 {
  padding: 0.5rem !important;
}

.heading__pad-horizontal-small___3UjPi {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.heading__pad-vertical-none___35zng {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.heading__pad-top-small___caxDH {
  padding-top: 0.5rem !important;
}

.heading__pad-right-small___3tILJ {
  padding-right: 0.5rem !important;
}

.heading__pad-bottom-small___fpgOX {
  padding-bottom: 0.5rem !important;
}

.heading__pad-left-small___2bJL9 {
  padding-left: 0.5rem !important;
}

.heading__pad-medium___2AzKP {
  padding: 1rem !important;
}

.heading__pad-horizontal-medium___f7IJB {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.heading__pad-vertical-medium___2l1kd {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.heading__pad-top-medium___3cVpP {
  padding-top: 1rem !important;
}

.heading__pad-right-medium___1dYOk {
  padding-right: 1rem !important;
}

.heading__pad-bottom-medium___3Ujgl {
  padding-bottom: 1rem !important;
}

.heading__pad-left-medium___TAqjo {
  padding-left: 1rem !important;
}

.heading__pad-large___4wI35 {
  padding: 2rem !important;
}

.heading__pad-horizontal-large___2fYw4 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.heading__pad-vertical-large___7NhF3 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.heading__pad-top-large___2vpjW {
  padding-top: 2rem !important;
}

.heading__pad-right-large___3vh1s {
  padding-right: 2rem !important;
}

.heading__pad-bottom-large___3bbSk {
  padding-bottom: 2rem !important;
}

.heading__pad-left-large___1iPas {
  padding-left: 2rem !important;
}

.heading__pad-xlarge___QUy71 {
  padding: 3.5rem !important;
}

.heading__pad-horizontal-xlarge___K9PxY {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.heading__pad-vertical-xlarge___VdWEn {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.heading__pad-top-xlarge___36f55 {
  padding-top: 3.5rem !important;
}

.heading__pad-right-xlarge___3xtn5 {
  padding-right: 3.5rem !important;
}

.heading__pad-bottom-xlarge___3hhZ1 {
  padding-bottom: 3.5rem !important;
}

.heading__pad-left-xlarge___1Tvr8 {
  padding-left: 3.5rem !important;
}

.heading__pad-huge___1v6GQ {
  padding: 5rem !important;
}

.heading__pad-horizontal-huge___Ak1Z2 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.heading__pad-vertical-huge___f5Kjx {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.heading__pad-top-huge___ggYHW {
  padding-top: 5rem !important;
}

.heading__pad-right-huge___31DtF {
  padding-right: 5rem !important;
}

.heading__pad-bottom-huge___1sV-f {
  padding-bottom: 5rem !important;
}

.heading__pad-left-huge___2ZdBL {
  padding-left: 5rem !important;
}

.heading__size-h1___1Z5Al {
  font-size: 3rem;
  line-height: 3rem;
  padding: 0;
  font-weight: 400;
}

.heading__size-h2___3dcKr {
  font-size: 2.125rem;
  line-height: 2.5rem;
  padding: 0;
  font-weight: 400;
}

.heading__size-h3___3gyrm {
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 0;
  font-weight: 600;
}

.heading__size-h4___3Hvck {
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0;
  font-weight: normal;
  font-weight: 400;
}

.heading__size-h5___XSw8y {
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0;
  font-weight: 600;
}

.heading__size-h6___3tGUf {
  font-size: 0.75rem;
  line-height: 1.125rem;
  padding: 0;
  font-weight: 400;
}

.heading__truncate-true___Aj-m1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.heading__inline-true___3a3b1 {
  display: inline;
}

.heading__uppercase-true___2_xDp {
  text-transform: uppercase;
}

.heading__weight-regular___1L88C {
  font-weight: 400;
}

.heading__weight-semibold___2qzpY {
  font-weight: 600;
}

/** separator **/

.heading__separator-top___1TiUN {
  border-top: 0.0625rem solid #DDDDDD;
}

.heading__separator-bottom___3RHzX {
  border-bottom: 0.0625rem solid #DDDDDD;
}

.heading__separator-left___2XE9N {
  border-left: 0.0625rem solid #DDDDDD;
}

.heading__separator-right___3BM-K {
  border-right: 0.0625rem solid #DDDDDD;
}

.heading__separator-horizontal___2H9Uh {
  border-top: 0.0625rem solid #DDDDDD;
  border-bottom: 0.0625rem solid #DDDDDD;
}

.heading__separator-vertical___3SX5h {
  border-left: 0.0625rem solid #DDDDDD;
  border-right: 0.0625rem solid #DDDDDD;
}

.heading__separator-all___39dsx {
  border: 0.0625rem solid #DDDDDD;
}

.heading__separator-none___lkHym {
  border: none;
}
