.atom-variation-loading__atomVariationLoading___cejsy {
  margin: 1rem;
}

.atom-variation-loading__headerText___2EBBA {
  margin: 1rem 1rem 0.5rem 1rem;
}

.atom-variation-loading__questionText___14dcT {
  margin-bottom: 0.5rem;
}

.atom-variation-loading__answerText___1PgEF {
  margin-top: 2rem;
}
