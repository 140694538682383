.desmos-atom__atomDesmos___2X415 {
  /* this is a hack to maintain a 4:3 width:height ratio */
  margin: 0 0 1rem 0;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  position: relative;
}

.desmos-atom__desmosAnchor___KL2YU {
  /* this is a hack to maintain a 4:3 width:height ratio */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.desmos-atom__spinner___1KWk- {
  padding-top: 1rem;
}

.desmos-atom__error___1jO7f {
  text-align: center;
  color: #C84E50;
}
