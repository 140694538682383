/**
 * Video Player
 */

[data-visible='false'] {
  display: none !important;
}

[data-whatintent='keyboard'] .video-player-skin__videoAtom___3E128 button:focus {
  outline: none;
  color: #000000;
  background: rgba(255, 255, 255, .7);
}

[data-whatintent='keyboard'] .video-player-skin__videoAtom___3E128 button:focus .captions-button-text {
  color: #FFFFFF;
}

[data-whatintent='keyboard'] .video-player-skin__videoAtom___3E128 .captions button:focus::before {
  background-color: #000000;
}

[data-whatintent='keyboard'] .video-player-skin__videoAtom___3E128 .video-slider:focus {
  outline: 0.3125rem solid Highlight;
}

.video-player-skin__videoAtom___3E128 {
  overflow: hidden;
  margin: 0 auto 0.625rem auto;
  max-height: 33.75rem;
  max-width: 100%;
  padding-bottom: 2rem;
  position: relative;
  background: #000000;
}

.video-player-skin__scale___2EqDL {
  height: 0;
  padding-bottom: 69%;
}

.video-player-skin__videoAtom___3E128:hover .video-controls {
  opacity: 1;
}

.video-player-skin__videoAtom___3E128:-webkit-full-screen {
  margin-bottom: 0;
  width: 100% !important;
  height: 100% !important;
  max-width: none;
  max-height: none;
}

.video-player-skin__videoAtom___3E128:-moz-full-screen {
  margin-bottom: 0;
  width: 100% !important;
  height: 100% !important;
  max-width: none;
  max-height: none;
}

.video-player-skin__videoAtom___3E128:-ms-fullscreen {
  margin-bottom: 0;
  width: 100% !important;
  height: 100% !important;
  max-width: none;
  max-height: none;
}

.video-player-skin__videoAtom___3E128:fullscreen {
  margin-bottom: 0;
  width: 100% !important;
  height: 100% !important;
  max-width: none;
  max-height: none;
}

.video-player-skin__videoAtom___3E128:-webkit-full-screen iframe {
  width: 100% !important;
  height: 100% !important;
  padding-bottom: 2rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.video-player-skin__videoAtom___3E128:-moz-full-screen iframe {
  width: 100% !important;
  height: 100% !important;
  padding-bottom: 2rem;
  box-sizing: border-box;
}

.video-player-skin__videoAtom___3E128:-ms-fullscreen iframe {
  width: 100% !important;
  height: 100% !important;
  padding-bottom: 2rem;
  box-sizing: border-box;
}

.video-player-skin__videoAtom___3E128:fullscreen iframe {
  width: 100% !important;
  height: 100% !important;
  padding-bottom: 2rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.video-player-skin__videoAtom___3E128 .video-player-skin__loading___1krFt:not(button):not(.video-player-skin__button___1Dp1a):not(a) {
  z-index: 4;
  background: #FFFFFF;
  outline: 0.0625rem solid #000000;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.video-player-skin__videoAtom___3E128 iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-player-skin__videoAtom___3E128 .video-controls {
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 2rem;
  bottom: 0rem;
  left: 0rem;
  right: 0rem;
  margin: auto;
  z-index: 5;
  background: #000000;
}

.video-player-skin__videoAtom___3E128 .video-controls.visible {
  opacity: 1;
}

.video-player-skin__videoAtom___3E128 .video-controls.with-quality-button .quality {
  display: inline-block;
}

.video-player-skin__videoAtom___3E128 .video-controls.with-quality-button .video-slider.track-slider {
  right: 10rem;
}

.video-player-skin__videoAtom___3E128 .video-controls.with-captions-button .captions {
  display: inline-block;
}

.video-player-skin__videoAtom___3E128 .video-controls.with-captions-button .video-slider.track-slider {
  right: 10rem;
}

.video-player-skin__videoAtom___3E128
  .video-controls.with-captions-button.with-quality-button
  .video-slider.track-slider {
  right: 12rem;
}

.video-player-skin__videoAtom___3E128 .left-side {
  float: left;
  line-height: 2rem;
}

.video-player-skin__videoAtom___3E128 button {
  background: none;
  width: 2rem;
  text-align: center;
  padding: 0;
  margin: 0;
  color: #FFFFFF;
  height: auto;
  line-height: 2rem;
  border-width: 0;
  display: inline-block;
  vertical-align: top;
  font-size: 1rem;
  font-family: 'Material Icons';
  font-weight: normal;
  cursor: pointer;
}

.video-player-skin__videoAtom___3E128 button:before {
  line-height: 32px;
  line-height: 2rem;
}

.video-player-skin__videoAtom___3E128 button:focus {
  outline: none;
}

.video-player-skin__videoAtom___3E128 button:hover:not(.video-player-skin__disabled___3LLde):not(:disabled) {
  background: none;
  color: #FFFFFF;
}

.video-player-skin__videoAtom___3E128 .play {
  font-family: 'Material Icons';
}

.video-player-skin__videoAtom___3E128 .play:before {
  content: '\E037';
}

.video-player-skin__videoAtom___3E128 .play.playing:before {
  content: '\E034';
}

.video-player-skin__videoAtom___3E128 .time {
  color: #FFFFFF;
  font-size: 0.75rem;
  font-weight: bold;
  display: inline-block;
  vertical-align: top;
  width: 2.5rem;
  padding-left: 0.4rem;
}

.video-player-skin__videoAtom___3E128 .right-side {
  float: right;
  line-height: 2rem;
}

.video-player-skin__videoAtom___3E128 .volume {
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.video-player-skin__videoAtom___3E128 .volume .slider-wrapper.video-player-skin__focused___NWFkV {
  opacity: 1;
  pointer-events: all;
}
.video-player-skin__videoAtom___3E128 .volume:hover .slider-wrapper {
  opacity: 1;
  pointer-events: all;
}

.video-player-skin__videoAtom___3E128 .volume button:before {
  content: '\E04D';
}

.video-player-skin__videoAtom___3E128 .volume button.muted:before {
  content: '\E04F';
}

.video-player-skin__videoAtom___3E128 .volume button:focus + .video-player-skin__slider-wrapper___ym54V {
  display: block;
}

.video-player-skin__videoAtom___3E128 .volume .slider-wrapper {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  bottom: 2rem;
  background-color: #000000;
  padding: 0.25rem 0;
  width: 1.875rem;
  left: 0;
  right: 0;
  margin: auto;
}

.video-player-skin__videoAtom___3E128 .volume .slider-wrapper .current-range {
  background-color: #FFFFFF;
}

.video-player-skin__videoAtom___3E128 .captions {
  color: #FFFFFF;
  position: relative;
  display: none;
  vertical-align: top;
}

.video-player-skin__videoAtom___3E128 .quality {
  color: #FFFFFF;
  position: relative;
  display: none;
  vertical-align: top;
}

.video-player-skin__videoAtom___3E128 .captions:hover {
  display: block;
}

.video-player-skin__videoAtom___3E128 .captions:focus {
  display: block;
}

.video-player-skin__videoAtom___3E128.captions:focus-within .options {
  display: block;
}

.video-player-skin__videoAtom___3E128 .quality:hover {
  display: block;
}

.video-player-skin__videoAtom___3E128 .quality button.enabled {
  -webkit-transition: -webkit-transform 100ms cubic-bezier(0.4, 0, 1, 1) 0ms;
  transition: -webkit-transform 100ms cubic-bezier(0.4, 0, 1, 1) 0ms;
  transition: transform 100ms cubic-bezier(0.4, 0, 1, 1) 0ms;
  transition: transform 100ms cubic-bezier(0.4, 0, 1, 1) 0ms, -webkit-transform 100ms cubic-bezier(0.4, 0, 1, 1) 0ms;
  -webkit-transform: rotate(15deg);
          transform: rotate(15deg);
}

.video-player-skin__videoAtom___3E128 .quality button {
  -webkit-transition: -webkit-transform 100ms cubic-bezier(0.4, 0, 1, 1) 0ms;
  transition: -webkit-transform 100ms cubic-bezier(0.4, 0, 1, 1) 0ms;
  transition: transform 100ms cubic-bezier(0.4, 0, 1, 1) 0ms;
  transition: transform 100ms cubic-bezier(0.4, 0, 1, 1) 0ms, -webkit-transform 100ms cubic-bezier(0.4, 0, 1, 1) 0ms;
  -webkit-transform: rotate(-15deg);
          transform: rotate(-15deg);
}

.video-player-skin__videoAtom___3E128 .quality:focus {
  display: block;
}

.video-player-skin__videoAtom___3E128 .quality:focus-within .options {
  display: block;
}

.video-player-skin__videoAtom___3E128 .quality .options fieldset {
  margin-right: 1.25rem;
  border: none;
}

.video-player-skin__videoAtom___3E128 .captions .options {
  display: none;
  position: absolute;
  bottom: 2rem;
  font-size: 0.75rem;
  overflow: hidden;
  padding: 1em;
  line-height: 1.1em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #000000;
}

.video-player-skin__videoAtom___3E128 .quality .options {
  display: none;
  position: absolute;
  bottom: 2rem;
  font-size: 0.75rem;
  overflow: hidden;
  padding: 1em;
  line-height: 1.1em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #000000;
}

.video-player-skin__videoAtom___3E128 .captions .options.enabled {
  display: block;
}

.video-player-skin__videoAtom___3E128 .quality .options.enabled {
  display: block;
  left: -1.25rem;
}

.video-player-skin__videoAtom___3E128 .captions .options.no-options {
  display: block;
  width: 20rem;
  right: 0;
}

.video-player-skin__videoAtom___3E128 .quality .options.no-options {
  display: block;
  width: 20rem;
  right: 0;
}

.video-player-skin__videoAtom___3E128 a {
  color: #008392;
}

.video-player-skin__videoAtom___3E128 .captions input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 0.0625rem;
  margin: -0.0625rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 0.0625rem;
}

.video-player-skin__videoAtom___3E128 .quality input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 0.0625rem;
  margin: -0.0625rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 0.0625rem;
}

.video-player-skin__videoAtom___3E128 .captions label {
  display: block;
  color: #FFFFFF;
  cursor: pointer;
}

.video-player-skin__videoAtom___3E128 .quality label {
  display: block;
  color: #FFFFFF;
  cursor: pointer;
}

.video-player-skin__videoAtom___3E128 .captions input:checked + span {
  color: #008392;
}

.video-player-skin__videoAtom___3E128 .captions label:hover {
  color: #008392;
}

.video-player-skin__videoAtom___3E128 .quality input:checked + span {
  color: #008392;
}

.video-player-skin__videoAtom___3E128 .quality label:hover {
  color: #008392;
}

.video-player-skin__videoAtom___3E128 .captions button {
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.6875rem;
  font-weight: 600;
}

.video-player-skin__videoAtom___3E128 .captions button:before {
  background-color: #FFFFFF;
  border-radius: 2px;
  border-radius: 0.125rem;
  content: '';
  position: absolute;
  width: 19px;
  width: 1.1875rem;
  height: 15px;
  height: 0.9375rem;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.video-player-skin__videoAtom___3E128 .captions .options {
  width: 5.625rem;
}

.video-player-skin__videoAtom___3E128 .captions .enabled:after {
  background-color: #FFFFFF;
  border-radius: 2px;
  border-radius: 0.125rem;
  content: '';
  display: block;
  width: 19px;
  width: 1.1875rem;
  border-bottom: 2px solid #008392;
  border-bottom: 0.125rem solid #008392;
  margin: auto;
  position: absolute;
  bottom: 6px;
  bottom: 0.375rem;
  left: 0;
  right: 0;
}

.video-player-skin__videoAtom___3E128 .captions-button-text {
  font-size: 0.75rem;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #000000;
}

.video-player-skin__videoAtom___3E128 .captions-button-secondary {
  padding-top: 0.3125rem;
  border-top: 0.0615rem solid #000000;
}

.video-player-skin__videoAtom___3E128 .quality button:before {
  content: '\E8B8';
}

.video-player-skin__videoAtom___3E128 .quality button .enabled:after {
  content: '';
  display: block;
  width: 19px;
  width: 1.1875rem;
  border-bottom: 2px solid #008392;
  border-bottom: 0.125rem solid #008392;
  margin: auto;
  position: absolute;
  bottom: 6px;
  bottom: 0.375rem;
  left: 0;
  right: 0;
}

.video-player-skin__videoAtom___3E128 .quality .options {
  text-transform: capitalize;
  width: 70rem;
}

.video-player-skin__videoAtom___3E128 .video-slider {
  border-radius: 0;
  background: transparent;
  border: none;
}

.video-player-skin__videoAtom___3E128 .video-slider .range {
  height: 0.3125rem;
}

.video-player-skin__videoAtom___3E128 .video-slider .range.current-range,
.video-player-skin__videoAtom___3E128 .video-slider .range .range {
  background: #008392;
  opacity: 0.7;
  z-index: 2;
}

.video-player-skin__videoAtom___3E128 .video-slider .range.future-range {
  background: rgba(255, 255, 255, .7);
  z-index: 1;
}

.video-player-skin__videoAtom___3E128 .video-slider .ui-slider-handle.ui-state-default {
  background: #000000;
  border: none;
  border-radius: 0;
  z-index: 3;
  visibility: hidden;
}

.video-player-skin__videoAtom___3E128 .video-slider.track-slider {
  left: 80rem;
  right: 8rem;
  height: 100%;
  position: absolute;
}

.video-player-skin__videoAtom___3E128 .video-slider.track-slider .range {
  height: 30rem;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.video-player-skin__videoAtom___3E128 .video-slider.track-slider .range.current-range,
.video-player-skin__videoAtom___3E128 .video-slider.track-slider .range .range {
  width: 0.0625rem;
}

.video-player-skin__videoAtom___3E128 .video-slider.track-slider .range.future-range {
  width: 100%;
  background-color: rgba(255, 255, 255, .7);
}

.video-player-skin__videoAtom___3E128 .video-slider.track-slider .ui-slider-handle.ui-state-default {
  height: 0.875rem;
  width: 0.1875rem;
  margin-left: 0.0625rem;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.video-player-skin__videoAtom___3E128 .video-slider.volume-slider {
  width: 100%;
  height: 4.375rem;
}

.video-player-skin__videoAtom___3E128 .video-slider.volume-slider .range {
  width: 20rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.video-player-skin__videoAtom___3E128 .video-slider.volume-slider .range.current-range {
  height: 0.0625rem;
  bottom: 0;
}

.video-player-skin__videoAtom___3E128 .video-slider.volume-slider .range.future-range {
  height: 100%;
}

.video-player-skin__videoAtom___3E128 .video-slider.volume-slider .ui-slider-handle.ui-state-default {
  height: 0.1875rem;
  width: 0.875rem;
  margin-bottom: 0.0625rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.video-player-skin__videoAtom___3E128 .full-screen {
  font-family: 'Material Icons';
}

.video-player-skin__videoAtom___3E128 .full-screen:before {
  content: '\E5D0';
}
