.login-alert__alertBox___DGONR {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  background-color: #FDF0F1;
  padding: 16px;
  font-size: 12px;
  line-height: 18px;
  white-space: pre-wrap;
}

.login-alert__alertImage___2gHbM {
  margin-right: 16px;
}
