.grade-update-snackbar__snackbar___xsTD7 {
    background-color: #3b3b3b;
    padding: 0.5rem 1rem;
    max-width: 37.5rem;
    left: 0;
    right: auto;
    margin: 0 0.5rem;
    border-radius: 0.25rem;
    z-index: 2000;
}

.grade-update-snackbar__active___fpgKG {
    -webkit-transform: translateY(-0.5rem) !important;
            transform: translateY(-0.5rem) !important;
}

.grade-update-snackbar__button___15c03 {
    margin: 0;
    padding: 0rem 0.5rem;
    color: #00A4B7 !important;
    font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.1em;
    display: inherit;
}

.grade-update-snackbar__button___15c03:hover {
    background-color: #3b3b3b !important;
}

.grade-update-snackbar__label___21mOD {
    color: #FFFFFF;
    font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    float: left;
    height: 2.25rem;
    line-height: 2.25rem;
}

.grade-update-snackbar__progressText___1M7Fv {
    color: #ABABAB !important;
    float: right;
    text-align: right;
    padding-left: 1rem;
}

@media screen and (max-width: 16.5rem) {
    .grade-update-snackbar__snackbar___xsTD7 {
        border-radius: 0;
    }
}
