.icon-with-shadow__iconWrapper___1jsGf {
  background: #F8F8F8;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin-right: 1rem;
  -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
          align-self: flex-start;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (min-width: 768px) {
  .icon-with-shadow__iconWrapper___1jsGf {
    width: 10rem;
    height: 10rem;
    margin: 0 0 2rem;
    -webkit-align-self: inherit;
        -ms-flex-item-align: inherit;
            align-self: inherit;
  }
}
