/**
 * The below selectors must score higher in specificity than their containing styles or they will
 * not take priority.
 **/

div.video-error__errorLoading___1iqUh {
  background: #000000;
  min-height: 20rem;
}

.video-error__errorLoading___1iqUh .video-error__errorMessage___1IFRJ {
  position: absolute;
  top: 45%;
  bottom: 0;
  width: 100%;
  font-size: 1rem;
  text-align: center;
}

.video-error__errorLoading___1iqUh .video-error__errorMessage___1IFRJ p.video-error__refresh___8zuDN {
  color: #FFFFFF;
}

.video-error__errorLoading___1iqUh .video-error__errorMessage___1IFRJ p.video-error__description___cZg4U {
  color: #FFFFFF;
}
