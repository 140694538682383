.nav-app-bar__appBar___1FGyw {
  height: 4rem;
  z-index: 1000;
  background-color: #21243C;
  position: relative !important; /** overrides absolute position in _header.scss from styleguide **/
}

.nav-app-bar__appBar-dark___3OaSU {
  background-color: #21243C;
}

.nav-app-bar__appBar-light___gw46G {
  background-color: #FFFFFF;
}

.nav-app-bar__horizontal___2x_kt .nav-app-bar__adminLink___1E_kB,
.nav-app-bar__horiztonal___16NED .nav-app-bar__adminLink___1E_kB:hover {
  color: #FFFFFF;
}

.nav-app-bar__menuIconLight___aFgOb {
  fill: #FFFFFF;
}

.nav-app-bar__menuIconDark___3vfKF {
  fill: #21243C;
}

.nav-app-bar__navLink___2YgIL {
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
}

.nav-app-bar__appBar-dark___3OaSU .nav-app-bar__navLink___2YgIL,
.nav-app-bar__appBar-light___gw46G .nav-app-bar__navLink___2YgIL {
  /* override react-toolbox styles */
  margin-right: 1rem;
  opacity: 1;
}

.nav-app-bar__appBar-dark___3OaSU .nav-app-bar__navLink___2YgIL {
  color: rgba(255, 255, 255, .7);
}

.nav-app-bar__appBar-dark___3OaSU .nav-app-bar__navLink___2YgIL:hover,
.nav-app-bar__appBar-dark___3OaSU .nav-app-bar__navLink___2YgIL:focus {
  color: #FFFFFF;
}

.nav-app-bar__appBar-light___gw46G .nav-app-bar__navLink___2YgIL {
  color: #727272;
}

.nav-app-bar__appBar-light___gw46G .nav-app-bar__navLink___2YgIL:hover,
.nav-app-bar__appBar-light___gw46G .nav-app-bar__navLink___2YgIL:focus {
  color: #484848;
}

.nav-app-bar__button___3eliF {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: transparent;
  border: 0;
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
      -ms-flex-order: -1;
          order: -1;
  cursor: pointer;
  margin-right: 1rem;
  padding: 0;
  line-height: normal; /* backwards compatibility with application.css */
}

.nav-app-bar__button___3eliF:hover {
  background: transparent !important; /* backwards compatibility with application.css */
}

.nav-app-bar__button___3eliF:focus {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
}

.nav-app-bar__button___3eliF:focus > svg {
  fill: #21243C;
}

.nav-app-bar__button___3eliF:active {
  background-color: #FFFFFF !important;
  border-radius: 50%;
}

.nav-app-bar__button___3eliF:active > svg {
  fill: #21243C;
}

.nav-app-bar__title___1k71W {
  font-size: 1.5rem;
  font-weight: 300;
  color: #484848; /* backwards compatibility with application.css */
}

.nav-app-bar__horizontal___2x_kt {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.nav-app-bar__logo___2o1H7 {
  display: none;
  margin: 0 1rem 0.1rem 1rem;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  -webkit-box-sizing: initial;
          box-sizing: initial;
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
      -ms-flex-order: -1;
          order: -1;
  height: 2.125rem;
}

.nav-app-bar__logoBorder____P12U {
  border-right: 1px solid #484848;
}

@media screen and (min-width: 480px) {
  .nav-app-bar__globalNavHamburger___1NmPh {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .nav-app-bar__logo___2o1H7 {
    display: inline-block;
  }
}

@media screen and (max-width: 480px) {
  .nav-app-bar__horizontal___2x_kt {
    display: none;
  }
  .nav-app-bar__appBar___1FGyw {
    padding-left: 16px;
  }
  .nav-app-bar__iconMenu___31IoN {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}
