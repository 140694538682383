.session-expired-modal__modalTitle___2-x0P {
  font-size: 34px;
  line-height: 40px;
  margin: 0;
  color: #484848;
  font-weight: 400;
  padding: 0 0 1.5rem 0;
}

.session-expired-modal__modalBody___2zMd8 {
  padding: 24px;
}
