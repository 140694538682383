.multi-choice__answer___3HSiW {
  margin: 0.5rem 0;
}

.multi-choice__text___2PhhA {
  margin-left: 2rem;
}

.multi-choice__explanation___2hjDp {
  padding: 1rem;
  border-left: 0.4rem solid #444;
  font-style: italic;
  margin: 1rem 0 1rem 1.5rem;
}

.multi-choice__answer___3HSiW .video-atom,
.multi-choice__answer___3HSiW .atom-image {
  margin: 0;
  text-align: left;
}

.multi-choice__general-explanation___2KnJn {
  padding: 1rem;
  border-left: 0.5rem solid #444;
  font-style: italic;
}

.multi-choice__answer___3HSiW .multi-choice__text___2PhhA table[data-source='cms-table'] {
  margin: 0;
}
