.tag__default___2NOuf {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  /* Tags cannot shrink */
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  white-space: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  justify-items: center;
  border-width: 1px;
  border-style: solid;
  /* Produces a pill shape */
  border-radius: 9999px;
  padding: 0.25rem 0.375rem;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.33em;
  min-width: 2rem;
  cursor: default;
}

.tag__theme-primary___189bF {
  color: #FFFFFF;
  background-color: #636BB5;
  border-color: #636BB5;
  fill: #FFFFFF;
}

.tag__theme-secondary___TS35f {
  color: #727272;
  background-color: transparent;
  border-color: #DDDDDD;
  fill: #727272;
}

.tag__theme-success___1MaRC {
  color: #008765;
  background-color: #E7F7F3;
  border-color: #008765;
  fill: #008765;
}

.tag__theme-warning___6Ld27 {
  color: #C15100;
  background: #FFF9F2;
  border-color: #C15100;
  fill: #C15100;
}

.tag__theme-warning___6Ld27 svg {
  fill: #C15100;
}

.tag__theme-error___3BKaD {
  color: #C84E50;
  background-color: #FDF0F1;
  border-color: #C84E50;
  fill: #C84E50;
}

.tag__theme-blue___3_l95 {
  color: #FFFFFF;
  background-color: #008392;
  border-color: #008392;
  fill: #FFFFFF;
}

.tag__theme-info___ii464 {
  color: #636BB5;
  background-color: #F5F5FF;
  border-color: #636BB5;
  fill: #636BB5;
}

.tag__theme-primary50___2wmvz {
  color: #373C64;
  background-color: #F5F5FF;
  border-color: #F5F5FF;
  fill: #F5F5FF;
}

.tag__theme-primary100___1QT98 {
  color: #21243C;
  background-color: #DADCF5;
  border-color: #DADCF5;
  fill: #DADCF5;
}

.tag__margin-none___5oWt6 {
  margin: 0;
}

.tag__margin-horizontal-none___3KAnz {
  margin-left: 0;
  margin-right: 0;
}

.tag__margin-vertical-none___3yhm4 {
  margin-top: 0;
  margin-bottom: 0;
}

.tag__margin-top-none___1RYnu {
  margin-top: 0;
}

.tag__margin-right-none___3whev {
  margin-right: 0;
}

.tag__margin-bottom-none___2RZQQ {
  margin-bottom: 0;
}

.tag__margin-left-none___35mO3 {
  margin-left: 0;
}

.tag__margin-small___1kt2f {
  margin: 0.5rem;
}

.tag__margin-vertical-small___3nZlW {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.tag__margin-horizontal-small___3_juR {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.tag__margin-vertical-none___3yhm4 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.tag__margin-top-small___HLTPz {
  margin-top: 0.5rem;
}

.tag__margin-right-small___1cA7r {
  margin-right: 0.5rem;
}

.tag__margin-bottom-small___1-3kq {
  margin-bottom: 0.5rem;
}

.tag__margin-left-small___1APGj {
  margin-left: 0.5rem;
}

.tag__margin-medium___3CMPM {
  margin: 1rem;
}

.tag__margin-horizontal-medium___cqXyR {
  margin-left: 1rem;
  margin-right: 1rem;
}

.tag__margin-vertical-medium___3m-S6 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tag__margin-top-medium___3-VEa {
  margin-top: 1rem;
}

.tag__margin-right-medium___2o5RK {
  margin-right: 1rem;
}

.tag__margin-bottom-medium___3_Rql {
  margin-bottom: 1rem;
}

.tag__margin-left-medium___1OOs6 {
  margin-left: 1rem;
}

.tag__margin-large___iYZXT {
  margin: 2rem;
}

.tag__margin-horizontal-large___JCXy6 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.tag__margin-vertical-large___lWLbY {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.tag__margin-top-large___1HJVB {
  margin-top: 2rem;
}

.tag__margin-right-large___tkWIW {
  margin-right: 2rem;
}

.tag__margin-bottom-large___3kKxf {
  margin-bottom: 2rem;
}

.tag__margin-left-large___3D2ZW {
  margin-left: 2rem;
}

.tag__margin-xlarge___3HRw5 {
  margin: 3.5rem;
}

.tag__margin-horizontal-xlarge___2Cs3D {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}

.tag__margin-vertical-xlarge___1xxcA {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.tag__margin-top-xlarge___24dYi {
  margin-top: 3.5rem;
}

.tag__margin-right-xlarge___3xCsR {
  margin-right: 3.5rem;
}

.tag__margin-bottom-xlarge___11rgF {
  margin-bottom: 3.5rem;
}

.tag__margin-left-xlarge___3yMvN {
  margin-left: 3.5rem;
}

.tag__margin-huge___3sG2k {
  margin: 5rem;
}

.tag__margin-horizontal-huge___3oMyS {
  margin-left: 5rem;
  margin-right: 5rem;
}

.tag__margin-vertical-huge___2_LpE {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.tag__margin-top-huge___2xS_s {
  margin-top: 5rem;
}

.tag__margin-right-huge___lKNlY {
  margin-right: 5rem;
}

.tag__margin-bottom-huge___lu4l3 {
  margin-bottom: 5rem;
}

.tag__margin-left-huge___3WCWS {
  margin-left: 5rem;
}

.tag__label___2dAyh {
  margin: 0 0.375rem;
}

.tag__uppercase-true___9rDUG {
  text-transform: uppercase !important;
}

.tag__faded___3BTU2 {
  opacity: 0.3;
}
