/** Hide the default icon */
body .embeddedServiceHelpButton .embeddedServiceIcon {
  display: none !important;
}

/**
  Hide the text in the label <span />.
  We don't use display=none because otherwise it breaks accessibility. The screen reader needs
  to read the content of this element.
*/
body .embeddedServiceHelpButton .uiButton .helpButtonLabel > span {
  display: block;
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
}

/** Add our speech bubble icon */
body .embeddedServiceHelpButton .uiButton .helpButtonLabel::after {
  content: '';
  background-image: url(/jsx/img/speech-bubble.6b7ae9de350db5a05d18.svg);
  background-size: 36px 40px;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  width: 60px;
  height: 60px;
}

/** When the widget is minimized, there shouldn't be a gap with the bottom of the page */
body .sidebarHeader.minimizedContainer.helpButton.embeddedServiceSidebarMinimizedDefaultUI {
  bottom: 0;
}

/** Resize and position our floating button */
body .embeddedServiceHelpButton .helpButton {
  right: 20px;
  bottom: 20px;
  width: 60px;
  height: 60px;
}

/** Change the shape and stylize the button */
body .embeddedServiceHelpButton .helpButton .uiButton {
  min-width: 60px;
  width: 60px;
  height: 60px;
  padding: 0;
  margin: 0;
  right: 0;
  bottom: 0;
  background: #007eb6;
  border-radius: 10px 10px 10px 0;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .33);
          box-shadow: 0 0 10px rgba(0, 0, 0, .33);
  cursor: pointer;
}

body .embeddedServiceHelpButton .helpButton .uiButton:hover {
  background: #005980;
}

/** Disabled state of the button */
body .embeddedServiceHelpButton .helpButton .uiButton.helpButtonDisabled,
body .embeddedServiceHelpButton .helpButton .uiButton.helpButtonDisabled:hover {
  background: #cccccc;
  cursor: default;
}

/** Make the icon fade in and out after it has been clicked */
body
  .embeddedServiceHelpButton
  .embeddedServiceIcon[style*='none']
  + .helpButtonLabel::after {
  opacity: 1;
  -webkit-animation: fade-in-out 1s ease-in-out infinite;
          animation: fade-in-out 1s ease-in-out infinite;
}

/** Hide some weird over element */
body .embeddedServiceHelpButton .helpButton .helpButtonEnabled:hover::before,
body .embeddedServiceHelpButton .helpButton .helpButtonEnabled:focus::before {
  display: none !important;
}

/** Update the text in the chat header to be white */
body h2[embeddedService-chatHeader_chatHeader] {
  color: white;
}

/** The fade in and out animation for the speech icon after the user has clicked the buttpn */
@keyframes fade-in-out {
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}
