.empty-state__emptyState___1RgRW {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 2.5rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

.empty-state__emptyStateIcon___34A-R {
  width: 14.875rem;
  height: 15rem;
  margin-bottom: 2.5rem;
}

.empty-state__header___1rejG {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.empty-state__description___vM5Xb {
  font-size: 1rem;
  line-height: 1.5rem;
  width: 39.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.empty-state__plusIcon___2J9u5 {
  fill: white;
  width: 1rem;
  height: 1rem;
  display: block;
}

.empty-state__menuOption___3kI_y {
  width: 95%;
  padding-left: 1.25rem;
}

.empty-state__message___iViTr {
  color: white;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
