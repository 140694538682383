.list__list___F5RGC {
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  padding: 0;
  white-space: normal;
}

.list__listItem___3Vojw a {
  text-decoration: none;
}

.list__listItem___3Vojw p {
  margin: 0;
}

.list__listItem___3Vojw {
  font-family: "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  line-height: 24px;
  min-height: 56px;
  color: #484848;
}

.list__listItem___3Vojw:focus,
.list__listItem___3Vojw.list__focus___17p6M {
  outline-offset: -2px;
}

.list__listItem___3Vojw a:focus > span,
.list__listItem___3Vojw:focus > span {
  -webkit-box-shadow: inset 0 0 0 5px #5599d8;
          box-shadow: inset 0 0 0 5px #5599d8;
}

[data-whatintent='mouse'] .list__listItem___3Vojw a:focus > span,
[data-whatintent='mouse'] .list__listItem___3Vojw:focus > span {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.list__divider___3kKas {
  background: #DDDDDD;
}

.list__selectable___2Zyjp:hover {
  background: #F8F8F8;
  cursor: pointer;
}

.list__list___F5RGC hr {
  margin: 0 !important;
}

@media screen and (max-width: 480px) {
  .list__selectable___2Zyjp {
    padding: 16px;
  }
}
