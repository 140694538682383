.page-padding__wrapper___2bO3P {
  padding-left: 1rem;
  padding-right: 1rem;
}

.page-padding__fullWidth___ZC-ZA {
  width: 100%;
}

/* 960px + 1rem */
@media (min-width: 976px) {
  .page-padding__wrapper___2bO3P {
    padding-left: 0;
    padding-right: 0;
  }
}
