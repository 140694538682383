.video-controls__trackSlider___3gkr0 {
  position: absolute;
  left: 85px;
  right: 120px;
  height: 100%;
}

.video-controls__trackSlider___3gkr0 .rc-slider-track {
  top: 0;
  bottom: 0;
}

.video-controls__trackSlider___3gkr0 .rc-slider-handle {
  top: 0;
  bottom: 0;
}

.with-captions-button .video-controls__trackSlider___3gkr0 {
  right: 155px;
}

.with-captions-button.with-quality-button .video-controls__trackSlider___3gkr0 {
  right: 190px;
}

.video-controls__volumeSlider___3AJBU {
  height: 70px;
  width: 22px;
}

.video-controls__volumeSlider___3AJBU .rc-slider-track {
  left: 0;
  right: 0;
}

.video-controls__videoSlider___1Nt58 .rc-slider-handle {
  left: 0;
  right: 0;
}
