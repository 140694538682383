/* Styles related to tables in CMS content */

table[data-source='cms-table'] {
  margin: 0 auto;
  border-spacing: 0;
}

table[data-source='cms-table'] th,
table[data-source='cms-table'] td {
  border-bottom: 1px solid #DDDDDD;
  padding: 0.3rem 1.2rem 0.3rem 1.2rem;
}

table[data-type='math-table'] th,
table[data-type='math-table'] td {
  text-align: center;
}

table[data-type='number-table'] th,
table[data-type='number-table'] td {
  text-align: right;
}

table[data-type='text-table'] th,
table[data-type='text-table'] td {
  text-align: left;
}

/* Tables in question body in the assignment cover preview page */
.question.user-content table[data-source='cms-table'] {
  margin-top: 0;
  margin-bottom: 0;
}

/* Tables in multiple choice/select answer choices using older Content component */
.multiple-choice .answer .text table[data-source='cms-table'],
.user-content-mc table[data-source='cms-table'] {
  margin: 0;
}

/* Tables in multiple choice/select answer choices using newer Content component */
div[role='group'] table[data-source='cms-table'] {
  margin: 0;
  padding: 0;
}
